import HandsIcon from '../../../Assets/icons/hands.svg'
import CycleIcon from '../../../Assets/icons/cycle.svg'
import ConjoinArrowsIcon from '../../../Assets/icons/conjoin-arrows.svg'
import SmileyIcon from '../../../Assets/icons/smiley.svg'
import ChatBubbleIcon from '../../../Assets/icons/chat-bubble.svg'
import EscalationIcon from '../../../Assets/icons/escalation.svg'

const SolutionsChart = () => {
    return (
        <>
            <header className='section-header'>
                <h2>Measurable Metrics, That Matter</h2>
            </header>
            <article className='homepage-solutions'>
                <section>
                    <img src={HandsIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Attract the Right Talent</h3>
                </section>
                <section>
                    <img src={ConjoinArrowsIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Align Efforts on Key Initiatives</h3>
                </section>
                <section>
                    <img src={ChatBubbleIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Reduce The Number of Escalations</h3>
                </section>
                <section>
                    <img src={SmileyIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Improve Employee Satisfaction Scores</h3>
                </section>
                <section>
                    <img src={EscalationIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Increase Internal Promotion Rates</h3>
                </section>
                <section>
                    <img src={CycleIcon} alt="" loading='lazy' />
                    <h3 className='text-header'>Improve Retention Rates</h3>
                </section>
            </article>
        </>
    )
}

export default SolutionsChart
