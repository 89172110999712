import { StoryblokComponent, storyblokEditable } from "@storyblok/react"
import ResourceFilterBar from '../../Communities/PublicWebsite/Resources/ResourceFilterBar'
import { topicsData, rolesData, typesData } from '../../Communities/PublicWebsite/Resources/ResourceFilterData'

const StoryblokResourceLanding = ({ blok, type }) => {
    const headerDisplayType = type === undefined ? 'coaching-library-home' : type
    const displayedSectionHeader = blok.headers.filter(component => component.resourceType === headerDisplayType)[0]

    // create an array of the possible url values for filtering
    const topicsArray = topicsData.map(a => a.value);
    const rolesArray = rolesData.map(a => a.value);
    const typeArray = typesData.map(a => a.value);
    
    // set the filtered data to the value from the url
    let filteredCards = blok.body // default to all resources
    if(topicsArray.includes(type)){
        filteredCards = blok.body.filter(card => card.topic.includes(type))
    } else if(rolesArray.includes(type)){
        filteredCards = blok.body.filter(card => card.role.includes(type))
    } else if(typeArray.includes(type)){
        filteredCards = blok.body.filter(card => card.type === type)
    } else if(type === 'our-solutions'){
        filteredCards = blok.body.filter(card => card.type === type)
    } else if(type === undefined || type === 'coaching-library-home'){
        filteredCards = blok.body
    }


    return (
        <main className='sprout-typography' {...storyblokEditable(blok)}>
            {blok.header ? <StoryblokComponent blok={blok.header[0]} /> : null}

            <ResourceFilterBar bloks={blok.body} />

            {blok.headers ? <StoryblokComponent blok={displayedSectionHeader} /> : null}

            <article className='coachingLibrary-card-wrap'>
                {filteredCards ? 
                    filteredCards.map((blok) => (
                        <StoryblokComponent blok={blok} key={blok._uid} />
                    ))
                : null}
            </article>
        </main>
    )
}

export default StoryblokResourceLanding
