import { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { Route, Switch } from 'react-router-dom'
import { PublicRoute }  from './shared/Routes/PublicRoute'
import { withAuth } from './Context/AuthProvider'
import { withUsers } from './Context/UsersProvider'
import { ExternalRedirect } from './GlobalComponents/ExternalRedirects/ExternalRedirect'
import { registerDomain } from './config/Config'
//Routes
// import ProtectedRoute from './shared/Routes/ProtectedRoute'
// import ProtectedRouteV2 from './shared/Routes/ProtectedRouteV2'
// import LmsSnapshotQuiz from './GlobalComponents/Snapshots/LmsSnapshot/LmsSnapshotQuiz'
// import LmsSnapshotFeedbackRequest from './GlobalComponents/Snapshots/LmsSnapshot/LmsSnapshotFeedbackRequest'
import CoachingForPerformanceActionPlan from './GlobalComponents/pdfs/CoachingForPerformanceActionPlan'
import CoachingCertificationAgenda from './GlobalComponents/CoachingEventGuide/CoachingCertificationAgenda'
import CookieBanner from './GlobalComponents/Banners/CookieBanner'
// import IEShame from './GlobalComponents/Banners/IEShame'
// import LmsSnapshotsIndex from './Communities/ProtectedCommunities/Lms/LmsSnapshotsIndex'
import SeoBacklinkRoutes from './shared/Routes/SeoBacklinkRoutes'
import SubdomainRedirects from './shared/Routes/SubdomainRedirects'
import OldReactCommunityRoutes from './shared/Routes/OldReactCommunityRoutes'
import './Assets/FaIcons'
import Homepage from './Communities/PublicWebsite/Homepage/Homepage'
import Contact from './Communities/PublicWebsite/Contact/ContactPage'
const SolutionsLanding = lazy(() => import('./Communities/PublicWebsite/Solutions/SolutionsLanding'))
const SolutionsPagesLayout = lazy(() => import('./Communities/PublicWebsite/Solutions/SolutionsPagesLayout'))
const Events = lazy(() => import('./Communities/PublicWebsite/Events/EventsPage'))
const IOCEvents = lazy(() => import('./Communities/PublicWebsite/Events/IOCEventsPage'))
const CoachingEventDetails = lazy(() => import('./Communities/PublicWebsite/Events/PublicCoachingEvents/CoachingEventDetails'))
const GrowEventDetails = lazy(() => import('./Communities/PublicWebsite/Events/PublicCoachingEvents/GrowEventDetails'))
const BreakthroughEventDetails = lazy(() => import('./Communities/PublicWebsite/Events/PublicBreakthroughsEvents/BreakthroughEventDetails'))
// const EventRegistrations = lazy(() => import('./Communities/PublicWebsite/Events/EventRegistrations/EventRegistration'))
const ResourcesLanding = lazy(() => import('./Communities/PublicWebsite/Resources/ResourcesLanding'))
const Clients = lazy(() => import('./Communities/PublicWebsite/Clients/ClientsPage'))
const AboutUs = lazy(() => import('./Communities/PublicWebsite/About/AboutUs'))
const BlogHome = lazy(() => import('./Communities/PublicWebsite/Blog/BlogHome'))
const BlogPost = lazy(() => import('./Communities/PublicWebsite/Blog/BlogPost'))

// --------------------------

const ContinuingEducation = lazy(() => import('./Communities/PublicWebsite/ContinuingEducation/ContinuingEducation'))
const CoachingEventGuide = lazy(() => import('./GlobalComponents/CoachingEventGuide/CoachingEventGuide'))
// const Portal = lazy(() => import('./Communities/ProtectedCommunities/Portal/Portal'))
// const PortalRegister = lazy(() => import('./Communities/ProtectedCommunities/Portal/Register'))
// const ForgotPassword = lazy(() => import('./Communities/ProtectedCommunities/Portal/ForgotPassword'))
// const ResetPassword = lazy(() => import('./GlobalComponents/ResetPassword/ResetPassword'))
const PrivacyPolicy = lazy(() => import('./GlobalComponents/PrivacyPolicy/PrivacyPolicy'))
// const IOCSnapshotSurvey = lazy(() => import('./GlobalComponents/Snapshots/CoachingSnapshots/IOCSurveyStyle'))
const Feedback = lazy(() => import('./GlobalComponents/Feedback/Feedback'))
// const VirtualEventDetails = lazy(() => import('./GlobalComponents/VirtualShipping/VirtualShipping'))
const IOCDFurlIndex = lazy(() => import('./Communities/DigitalBursts/IOCDFIndex'))
const UnsubscribeFromEmail = lazy(() => import('./GlobalComponents/UnsubscribeFromEmail/UnsubscribeFromEmail'))
const IOCWorkbookGate = lazy(() => import('./GlobalComponents/ResourceGate/IOCWorkbookGate'))
const VideoPlayer = lazy(() => import('./shared/VideoPlayer'))
const NotFound404 = lazy(() => import('./GlobalComponents/NotFound404/NotFound404'))
// Protected Routes 
// const DashboardHome = lazy(() => import('./Communities/ProtectedCommunities/Dashboard/DashboardLanding'))
// const A2bHome = lazy(() => import('./Communities/ProtectedCommunities/a2b/A2bIndex'))
// const IOCDFIndex = lazy(() => import('./Communities/ProtectedCommunities/DigitalFoundations/IOCDFIndex'))
// const BTDFIndex = lazy(() => import('./Communities/ProtectedCommunities/DigitalFoundations/BTDFIndex'))
// const IocEduIndex = lazy(() => import('./Communities/ProtectedCommunities/DigitalFoundations/IocEdu/IocEduIndex'))
// const FeaturesHome = lazy(() => import('./Communities/ProtectedCommunities/Global/FeaturesHome'))
// const GrowCoachingIndex = lazy(() => import('./Communities/ProtectedCommunities/GrowCoaching/GrowCoachingIndex'))
const AreasWeServe = lazy(() => import('./Communities/PublicWebsite/AreasWeServe/AreasWeServe'))
const Resources = lazy(() => import('./Communities/PublicWebsite/Resources/Resources'))
const ResourcePost = lazy(() => import('./Communities/PublicWebsite/Resources/ResourcePost'))
// const OpportunityLanding = lazy(() => import('./GlobalComponents/OpportunityForms/OpportunityLanding'))
// // Modular Communities
// const ParticipantPortalIndex = lazy(() => import('./Communities/ProtectedCommunities/ParticipantPortal/ParticipantPortalIndex'))


let isShowingCookieBanner = true;
let token = localStorage.getItem('iodToken')
let declinedCookie = sessionStorage.getItem('declinedCookie')
let acceptedCookie = Cookies.get('SESSID')

if(token || declinedCookie || acceptedCookie) isShowingCookieBanner = false
// const isIE = false || !!document.documentMode;


const App = () => {

  const renderLoader = () => <p>Loading...</p>

  return (
    <div className='App'>
      <Helmet titleTemplate="%s | InsideOut Development" defaultTitle="InsideOut Development" />
      {isShowingCookieBanner && <CookieBanner/> }
      <Suspense fallback={renderLoader()}>
        <Switch>
          <PublicRoute exact path='/' component={Homepage} />
          <PublicRoute exact path='/solutions' component={SolutionsLanding} />
          <PublicRoute path='/solutions/:solution' component={SolutionsPagesLayout} />
          <PublicRoute path='/contact' component={Contact} />
          <PublicRoute path='/ceu' component={ContinuingEducation} />
          <PublicRoute path='/event/ioc-event-pdf-guide' component={CoachingEventGuide} />
          <PublicRoute path='/pdfs/coaching-for-performance-action-plan' component={CoachingForPerformanceActionPlan} />
          <PublicRoute path='/event/ioc-certification-agenda' component={CoachingCertificationAgenda} />
          <PublicRoute path='/events' component={Events} />
          <PublicRoute path='/event/ioc/:id' component={CoachingEventDetails} />
          <PublicRoute path='/event/grow/:id' component={GrowEventDetails} />
          <PublicRoute path='/event/breakthrough/:id' component={BreakthroughEventDetails} />
          <PublicRoute exact path='/resources-landing' component={ResourcesLanding} />
          <PublicRoute exact path='/resources' component={Resources} />
          <PublicRoute exact path='/resources/:type' component={Resources} />
          <PublicRoute path='/resources/case-studies/:slug' component={ResourcePost} />
          <PublicRoute path='/resources/articles/:slug' component={ResourcePost} />
          <PublicRoute path='/resources/ebooks/:slug' component={ResourcePost} />
          <PublicRoute path='/resources/our-solutions/:slug' component={ResourcePost} />
          <PublicRoute path='/our-clients' component={Clients} />
          <PublicRoute path='/about-us' component={AboutUs} />
          <PublicRoute exact path='/blog' component={BlogHome} />
          <PublicRoute path='/blog/:slugId' component={BlogPost} />
          <PublicRoute path='/ioc/events' component={IOCEvents} />
          <PublicRoute path='/coaching-library' component={Resources} />
          <PublicRoute path='/virtual-delivery' component={() => ExternalRedirect("https://insideoutdev.com/resources/our-solutions/insideout-coaching-virtual-brochure")} />
          <PublicRoute path='/privacy-policy' component={PrivacyPolicy} />
          <PublicRoute path='/feedback/:id' component={Feedback} site={'Feedback'}/>
          <PublicRoute path='/unsubscribe/:id' component={UnsubscribeFromEmail}/>
          <PublicRoute path='/content-gate/:id' component={IOCWorkbookGate} />
          {/* Video Links */}
          <PublicRoute path='/videos/:id' component={() => <VideoPlayer  videoName="Don't Kill Performance - Alan Fine" videoLength="19:49" videoURL="https://insideoutdev.wistia.com/medias/m1x5luwl2h" />} />
          <PublicRoute path='/ioc_intro' component={() => <VideoPlayer  videoName="InsideOut Coaching Community Introduction" videoLength="22:41" videoURL="https://insideoutdev.wistia.com/medias/ul0lrhnj0x" />} />
          <PublicRoute exact path='/areas-we-serve' component={AreasWeServe} />
          <PublicRoute exact path='/areas-we-serve/:solution' component={SolutionsPagesLayout} />
          {/* Duplicate Links listed in other resources */}
          <PublicRoute exact path='/aboutiod' component={AboutUs} />
          <PublicRoute exact path='/training' component={Homepage} />
          {/* Routes with specific header/footer styles */}
          <Route path='/digital-bursts' component={IOCDFurlIndex} />
          {/* External redirects */}
          <PublicRoute exact path='/site/audio_download_splash' component={() => ExternalRedirect("https://legacy.insideoutdev.com/site/audio_download_splash")} />
          <PublicRoute exact path='/vshare-bt-10' component={() => ExternalRedirect("https://legacy.insideoutdev.com/vshare-bt-10")} />
          <PublicRoute exact path='/vshare_ioc_iod_2016' component={() => ExternalRedirect("https://legacy.insideoutdev.com/vshare_ioc_iod_2016")} />
          <PublicRoute exact path='/vshare_iobreakthrough' component={() => ExternalRedirect("http://legacy.insideoutdev.com/vshare_iobreakthrough")} />
          <PublicRoute path='/live-online/shipping/:id' component={() => ExternalRedirect(`${registerDomain}/live-online/shipping/:id`)} />
          <PublicRoute path='/logistics/onsite/:id' component={() => ExternalRedirect(`${registerDomain}/logistics/onsite/:id`)} />
          <PublicRoute path='/logistics/virtual/:id' component={() => ExternalRedirect(`${registerDomain}/logistics/virtual/:id`)} />

          {/* Subdomain Redirects */}
          {/* register.insideoutdev.com */}
          {/* <PublicRoute path='/register/:id' component={() => ExternalRedirect(`${registerDomain}/register/:id`)} /> */}
          {/* app.insideoutdev.com */}
          {/* <Route path='/portal/login' component={() => ExternalRedirect(`${appDomain}/portal/login`)} /> */}
          {/* <Route path='/portal/register/' component={() => ExternalRedirect(`${appDomain}/portal/register/`)} /> */}
          {/* <Route path='/portal/register/:id' component={() => ExternalRedirect(`${appDomain}/portal/register/:id`)} /> */}
          {/* <Route path='/forgot-password' component={() => ExternalRedirect(`${appDomain}/forgot-password`)} /> */}
          {/* <Route path='/password-reset/:id' component={() => ExternalRedirect(`${appDomain}/password-reset/:id`)} /> */}
          
          {/* <Route path='/snapshots/coaching-snapshot/v1/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v1/`)} /> */}
          {/* <Route path='/snapshots/coaching-snapshot/v2/feedback/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v2/feedback/`)} /> */}
          {/* <Route path='/snapshots/coaching-snapshot/v2/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v2/`)} /> */}
          {/* <Route path='/dashboard' component={() => ExternalRedirect(`${appDomain}/dashboard`)} /> */}
          {/* <ProtectedRoute path='/a2b' component={A2bHome} /> */}
          {/* <ProtectedRoute path='/digital-foundations/iocdf' component={IOCDFIndex} /> */}
          {/* <ProtectedRoute path='/digital-foundations/btdf' component={BTDFIndex} /> */}
          {/* <ProtectedRoute path='/digital-foundations/iocedu' component={IocEduIndex} /> */}
          {/* <Route path='/features' component={() => ExternalRedirect(`${appDomain}/features`)} /> */}
          {/* <ProtectedRoute path='/lms/snapshots' component={LmsSnapshotsIndex} /> */}
          {/* <ProtectedRoute path='/grow-coaching' component={GrowCoachingIndex} /> */}
          {/* <ProtectedRouteV2 path='/participant-portal' component={ParticipantPortalIndex} /> */}

          <Route path='/register/:id' component={SubdomainRedirects} />
          <Route path='/portal/login' component={SubdomainRedirects} />
          <Route path='/portal/register/' component={SubdomainRedirects} />
          <Route path='/portal/register/:id' component={SubdomainRedirects} />
          <Route path='/forgot-password' component={SubdomainRedirects} />
          <Route path='/password-reset/:id' component={SubdomainRedirects} />
          <Route path='/snapshots/coaching-snapshot/v1/' component={SubdomainRedirects} />
          <Route path='/snapshots/coaching-snapshot/v2/feedback/' component={SubdomainRedirects} />
          <Route path='/snapshots/coaching-snapshot/v2/' component={SubdomainRedirects} />
          <Route path='/dashboard' component={SubdomainRedirects} />
          <Route path='/a2b' component={SubdomainRedirects} />
          <Route path='/digital-foundations/iocdf' component={SubdomainRedirects} />
          <Route path='/digital-foundations/btdf' component={SubdomainRedirects} />
          <Route path='/digital-foundations/iocedu' component={SubdomainRedirects} />
          <Route path='/features' component={SubdomainRedirects} />
          <Route path='/lms/snapshots' component={SubdomainRedirects} />
          <Route path='/grow-coaching' component={SubdomainRedirects} />
          <Route path='/participant-portal' component={SubdomainRedirects} />

          <OldReactCommunityRoutes />
          <SeoBacklinkRoutes />
          <PublicRoute path='*' component={NotFound404} />
        </Switch>
      </Suspense>
    </div>
  )
}

export default withAuth(withUsers(App))
