import SemiCircleHeader from '../../Communities/PublicWebsite/Shared/SemiCircleHeader'

const CoachingForPerformanceActionPlan = () => {

	return (
		<main style={{width: '100%'}}>
			<SemiCircleHeader headerText='Coach for Performance' />
			<iframe  className='pdf-full-screen' src='https://insideoutdev.sfo2.digitaloceanspaces.com/iod/pdfs/InsideOut-Coach-for-Performance.pdf' title='Coaching Event Guide' ></iframe>
		</main>
	)
}

export default CoachingForPerformanceActionPlan
