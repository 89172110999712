import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'


const ResourceSearch = ({ displayMobile, bloks }) => {
    const [isSearching, setIsSearching] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const clearSearch = () => {
        // clear input
        // scroll to top of screen if mobile
        displayMobile && window.scrollTo(0, 0)
        // close search
        setSearchValue('')
        setSearchResults([])
        setIsSearching(false)
    }

    const filterResults = () => {
        let filteredBloks = bloks.filter(blok => blok.title.toUpperCase().includes(searchValue.toUpperCase()) || blok.author.toUpperCase().includes(searchValue.toUpperCase()))
        setSearchResults(filteredBloks)
    }

    const handleKeypress = e => {
        if (e.keyCode === 13 || e.charCode === 13) {
            filterResults();
        }
    };


    let searchedResources = searchResults.map((result, i) => (
        <li key={i}>
            <Link to={`/${result.link.cached_url}`}>
                <img src={result.thumbnail.filename} alt="" />
                <p>{result.title}</p>
            </Link>
        </li>
    ))

    const resourceFilterOnChange = (e) => {
        setSearchValue(e.target.value)
    }


    return (
        <div>
            {isSearching ?
            <>
                <div className='coachingLibrary-filterbar-overlay'></div>
                <section className='coachingLibrary-filterbar-search-open text-body'>
                    <aside>
                        <button onClick={filterResults}>
                            <FontAwesomeIcon icon='search' />
                        </button>
                        <input type="text" onChange={resourceFilterOnChange}  onKeyPress={handleKeypress} />
                        <button onClick={clearSearch}>
                            <FontAwesomeIcon icon='times-circle' />
                        </button>
                    </aside>
                </section>
                <ul className='coachingLibrary-filterbar-search-open-list'>
                    {searchedResources}
                </ul>
            </>
            :
                <button onClick={() => setIsSearching(true)}  className='coachingLibrary-filterbar-search'>
                    <FontAwesomeIcon icon='search' />
                </button>
            }
        </div>
    )
}

export default ResourceSearch
