import { useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOutsideClick } from '../../shared/CustomHooks/useOutsideClick'
import GlobalNavbarDropdown from './GlobalNavbarDropdown'
import DropdownAnimate from '../../shared/DropdownAnimate'


export const BaseNav = ({ name, destination, clickFn, classname, linkClassName}) => {
    return (
        <li key={name} className={classname}>
            <NavLink 
                to={destination} 
                className={linkClassName}
                activeClassName={`${linkClassName}-active`}
                onClick={clickFn && clickFn}
            >
                {name}
            </NavLink> 
        </li>
    )
}

export const ExternalNav = ({ name, destination, clickFn, classname, linkClassName }) => {
    return (
        <li key={name} className={classname}>
            <a 
                href={destination}  
                target="_blank" 
                rel="noopener noreferrer" 
                className={linkClassName}
                onClick={clickFn && clickFn}
            >
                {name}
            </a> 
        </li>
    )
}

export const HashNav = ({ name, destination, clickFn, classname, displayMobileNav, linkClassName }) => {
    const scrollWithOffset = (el) => {
        // Keeps element from being overlapped by header on mobile views
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -70; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    const scrollFn = el => {
        if(displayMobileNav){
            el.scrollIntoView({ behavior: 'smooth' })
        } else {
            scrollWithOffset(el)
        }
    }

    return (
        <li key={name} className={classname}>
            <HashLink 
                to={destination} 
                className={linkClassName}
                onClick={clickFn && clickFn}
                scroll={scrollFn}
            >
                {name}
            </HashLink>
        </li>
    )
}

export const DropdownNav = ({ name, classname, destination, dropdownLinks, linkClassName, dropdownClassName, displayMobileNav }) => {
    const navDropdownRef = useRef(null)
    const [ expandPanel, collapsePanel, expanded ] = useOutsideClick(navDropdownRef)
    const toggleDropdown = expanded ? collapsePanel : expandPanel

    if(displayMobileNav){
        return (
            <li className={classname} ref={navDropdownRef}>
                <span>
                    {name} 
                    <button className='dropdown-arrow' onClick={toggleDropdown} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={expanded ? "chevron-down" : "chevron-right"} /> 
                    </button>
                </span>
                <DropdownAnimate show={expanded}>
                    <ul className={dropdownClassName}>
                        <GlobalNavbarDropdown links={dropdownLinks} collapseDropdown={collapsePanel} displayMobileNav={displayMobileNav} />
                    </ul>
                </DropdownAnimate>
            </li>
        )
    } else {
        return (
            <li className={classname} onMouseOver={expandPanel} onMouseOut={collapsePanel} ref={navDropdownRef}>
                <NavLink to={destination} className={linkClassName} activeClassName={`${linkClassName}-active`}>{name}</NavLink> 
                <ul className={dropdownClassName} style={{visibility: expanded ? 'visible' : 'hidden'}}>
                    <GlobalNavbarDropdown links={dropdownLinks} collapseDropdown={collapsePanel} displayMobileNav={displayMobileNav} />
                </ul>
            </li>
        )
    }
}

export const SubDropdownNav = ({ name, classname, sublinks, dropdownClassName, displayMobileNav }) => {
    const subNavDropdownRef = useRef(null)
    const [ expandPanel, collapsePanel, expanded ] = useOutsideClick(subNavDropdownRef)
    const toggleDropdown = expanded ? collapsePanel : expandPanel

    if(displayMobileNav){
        return (
            <li className={classname} ref={subNavDropdownRef}>
                <span>
                    {name} 
                    <button className='dropdown-arrow' onClick={toggleDropdown} style={{paddingRight: '20px'}}>
                        <FontAwesomeIcon icon={expanded ? "chevron-down" : "chevron-right"} /> 
                    </button>
                </span>
                <DropdownAnimate show={expanded}>
                    <ul className={dropdownClassName}>
                        <GlobalNavbarDropdown links={sublinks} collapseDropdown={collapsePanel} displayMobileNav={displayMobileNav} />
                    </ul>
                </DropdownAnimate>
            </li>
        )
    } else {
        return (
            <>
                <li className={classname}>{name}</li>
                <GlobalNavbarDropdown links={sublinks} collapseDropdown={collapsePanel} displayMobileNav={displayMobileNav} />
            </>
        )
    }
}
