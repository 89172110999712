import { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCurrentWidth } from '../../../shared/CustomHooks/useCurrentWidth'
import { useSwipe } from '../../../shared/CustomHooks/useSwipe'
import { SuccessStoriesData } from './HomepageData'
import SuccessStoryCard from './SuccessStoryCard'

const SuccessStoriesSection = () => {
    const [currentIndex, setCurrentIndex] = useState(1)
    const [handleTouchStart, handleTouchMove, handleTouchEnd] = useSwipe()
    let currentWindowWidth = useCurrentWidth()

    const numberOfSuccessStories = SuccessStoriesData.length - 1

    const nextCard = useCallback(() => { 
        currentIndex < numberOfSuccessStories ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0)
    }, [currentIndex, numberOfSuccessStories])

    const previousCard = () => {
        currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(numberOfSuccessStories)
    }

    useEffect(() => {
        let interval = 15000
        let autoplay = numberOfSuccessStories > 2 ? true : false
        if(autoplay){ 
            interval = setInterval(() => nextCard(), interval);
        }
        return () => clearInterval(interval)
    }, [currentIndex, numberOfSuccessStories, nextCard])

    const successStoriesMap = SuccessStoriesData.map((caseStudy, i) => {
        const findCardLocation = () => {
            // set classNames to order display instead of index
            // If carousel is at end
            if(currentIndex === numberOfSuccessStories){
                if(i === currentIndex){
                    return 'two'
                } else if(i === 0){
                    return 'three'
                } else if(i === currentIndex - 1){
                    return 'one'
                } else {
                    return 'hidden'
                }
            // If carousel is at beginning
            } else if(currentIndex === 0){
                if(i === currentIndex){
                    return 'two'
                } else if(i === currentIndex + 1){
                    return 'three'
                } else if(i === numberOfSuccessStories){
                    return 'one'
                } else {
                    return 'hidden'
                }
            } else {
                if(i === currentIndex){
                    return 'two'
                } else if(i === currentIndex + 1){
                    return 'three'
                } else if(i === currentIndex - 1){
                    return 'one'
                } else {
                    return 'hidden'
                }
            }
        }
        
        return (
            <SuccessStoryCard key={i}
                caption={caseStudy.caption}
                image={caseStudy.image}
                name={caseStudy.name}
                displayClass={`card-${findCardLocation()}`}
                logo={caseStudy.logo}
            />
        )
    })

    return (
        <article className='carousels-case-studies'>
            <header className='section-header'>
                <h2>Customer Success Stories</h2>
            </header>
            <div className='carousels-case-studies-container'>
                {(numberOfSuccessStories + 1 > 3 || currentWindowWidth) <= 1024 &&
                    <FontAwesomeIcon icon={"caret-left"} className='carousels-case-studies-container-arrow' onClick={previousCard}/>
                }
                <section className='carousels-case-studies-container-map' onTouchStart={() => handleTouchStart()} onTouchMove={() => handleTouchMove()} onTouchEnd={() => handleTouchEnd(previousCard, nextCard)}>
                    {successStoriesMap}
                </section>
                {(numberOfSuccessStories + 1 > 3 || currentWindowWidth <= 1024) &&
                    <FontAwesomeIcon icon={"caret-right"} className='carousels-case-studies-container-arrow' onClick={nextCard}/>
                }
            </div>
        </article>
    )
}

export default SuccessStoriesSection
