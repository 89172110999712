import { storyblokEditable } from "@storyblok/react"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { render } from 'storyblok-rich-text-react-renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SemiCircleHeader from "../../Communities/PublicWebsite/Shared/SemiCircleHeader"


const StoryblokResourcePost = ({ blok }) => {

    const resourceFile = blok.resource.filename.replace('https://a.storyblok.com', 'https://s3.amazonaws.com/a.storyblok.com')

    let typeLink;
    switch(blok.type){
        case 'Articles':
            typeLink = 'articles'
            break;
        case 'Case Studies':
            typeLink = 'case-studies'
            break;
        case 'Ebooks':
            typeLink = 'ebooks'
            break;
        case 'Solutions': 
            typeLink = 'our-solutions'
            break;
        default:
            break;
    }


    return (
        <main {...storyblokEditable(blok)} className='coachingLibrary-post'>
            <Helmet>
                <title>{blok.title}</title>
                <meta name={blok.seo.title} description={blok.seo.description} />
            </Helmet>
            <SemiCircleHeader headerText='Coaching Library' {...storyblokEditable(blok)} />

            <nav className='coachingLibrary-post-paths' style={{overflowWrap: 'break-word'}}>
                <p className='text-body-lg'>
                    <span>
                        <Link to='/resources' className='text-body'>Home</Link>
                        <small>
                            <FontAwesomeIcon icon="angle-double-right" className='coachingLibrary-post-paths-arrow'/>
                        </small>
                        <Link to={`/resources/${typeLink}`} className='text-body'>{blok.type}</Link>
                        <small>
                            <FontAwesomeIcon icon="angle-double-right" className='coachingLibrary-post-paths-arrow'/>
                        </small>
                    </span>
                    {blok.title}
                </p>
            </nav>

            <article className='coachingLibrary-post-content'>    
                <iframe title="test-frame" src={resourceFile} type="application/pdf"  className='coachingLibrary-post-content-viewer'/>
                <a href={resourceFile} target="_blank" rel="noreferrer"  className='coachingLibrary-post-content-download'>Download PDF</a>
                <section className='coachingLibrary-post-content-summary'>
                    <h2 className='text-header'>{blok.title}</h2>
                    {/* Summary */}
                    <div>{render(blok.summary)}</div>
                </section>
            </article>
        </main>
    )
}

export default StoryblokResourcePost
