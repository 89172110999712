import { useState, useContext, createContext } from 'react'
import { authAxios } from '../shared/CustomAxios'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import highfiveEmail from '../shared/EmailTemplates/highfiveEmail'
import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
import { iodFrontEndUrl } from '../config/Config'

const highFivesBaseURL = '/v1/api/a2b/highfive'
const valueLikeBaseURL = '/v2/api/a2b/valuelikes'
const highFiveCommentBaseURL = '/v2/api/a2b/highfivecomments'
const HighFiveContext = createContext()

export const useHighFives = () => useContext(HighFiveContext)

export const HighFiveProviderV2 = ({ children }) =>  {
    const [allHighFives, setAllHighFives] = useState([])
    const [givenHighFives, setGivenHighFives] = useState(0)
    const [receivedHighFives, setReceivedHighFives] = useState([])
    const [viewingAll, setViewingAll] = useState(true)
    const [usersRecords, setUsersRecords] = useState([])
    const [initialUsers, setInitialUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [highFiveFormData, setHighFiveFormData] = useState({
        externalName: '',
        externalEmail: '',
        highFiveMessage: '',
    })
    const [highFiveSubmitted, setHighFiveSubmitted] = useState(null)
    const [highfivesUpdated, setHighfivesUpdated] = useState(false)

    const getAllHighFives = async (user) => {
        // reset the update state after it runs. Only used in dependency array
        setHighfivesUpdated(false)
        try {
            let res = await authAxios.get(`${highFivesBaseURL}`)
            const given = res.data.filter(sender => sender.userId === user.id)
            const received = res.data.filter(highfive => highfive.recipients.includes(`${user.firstName} ${user.lastName}`))
            setAllHighFives(res.data)
            setGivenHighFives(given.length)
            setReceivedHighFives(received)
        } catch (error) {
            console.log(error)
        }    
    }

    const initializeUserInfo = (users) => {
        let usersRecords = []
        let userRecord = {}
        users.forEach(employee => {         
            userRecord.name = `${employee.User.firstName} ${employee.User.lastName}`
            userRecord.email = employee.User.email
            userRecord.isEmployee = 1
            usersRecords.push(userRecord)
            userRecord = {}
        })
        setUsersRecords(usersRecords)
    }

    const toggleHighFiveView = () => setViewingAll(!viewingAll)

    const filterList = (e) => {
        const checkSelected = usersRecords.filter(employee => !selectedUsers.map(names => names['name']).includes(employee.name))
        let updatedList = checkSelected.filter(users => users !== selectedUsers && users.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setInitialUsers(updatedList)
        setSearchValue(e.target.value)
    }

    const toggleUsersInList = (user) => {
        let userName = user.name
        let userEmail = user.email
        if(!selectedUsers.map(names => names['name']).includes(userName) || !selectedUsers.map(emails => emails['email']).includes(userEmail)){
            setSelectedUsers([...selectedUsers, user])
            setInitialUsers([])
            setSearchValue('')
        } else {
            setSelectedUsers(selectedUsers.filter(employee => employee.name !== userName && employee.email !== userEmail))
            setSearchValue('')
        }
    }

    const addExternalRecipient = (e) => {
        e.preventDefault()

        if(highFiveFormData.email > 0){
            let externalRecipient = {
                name: highFiveFormData.externalName,
                email: highFiveFormData.externalEmail,
                isEmployee: 0
            }
            
            setSelectedUsers([...selectedUsers, externalRecipient])
            setHighFiveFormData({...highFiveFormData, externalName: '', externalEmail: '', highFiveMessage:''}) 
            setSearchValue('')
        }
    }

    const highFiveOnChange = (e) => {
        const { name, value } = e.target
        setHighFiveFormData({...highFiveFormData, [name]: sanitizeData(value)})
    }

    const highfiveEmailLoop = (user, recipients) => {
        recipients.forEach(recipient => {
            const highfiveData = { 
                emailRecipient: recipient, 
                iodFrontEndUrl: iodFrontEndUrl,
                emailSender: `${user.firstName} ${user.lastName}`,
                highfiveMessage: highFiveFormData.highFiveMessage,
                // agreements: agreements
            }

            let highfiveBody = highfiveEmail(highfiveData) 

            let senderBody = { 
                recipient: recipient.email, 
                subject: `${user.firstName} ${user.lastName} gave you a High Five!`, 
                emailTemplate: highfiveBody, 
                emailLogId: '',
                carbonCopy: user.email 
            }

            let logBody = { 
                senderEmail: user.email, 
                receiverEmail: recipient.email, 
                purpose: 'Given HighFive', 
                userId: user.id
            }

            sendNodeMailer(senderBody, logBody)
        })
    }

    const submitHighFive = async (e, user) => {
        e.preventDefault()
        let url = highFivesBaseURL

        const data = {  
            recipients: selectedUsers.map(names => names['name']).join(","),
            message: highFiveFormData.highFiveMessage,
            // agreements: agreements.join(",")
        }

        try {
            await authAxios.post(`${url}`, data)
            highfiveEmailLoop(user, selectedUsers)
            setHighFiveSubmitted(true)
        } catch (error) {
            setHighFiveSubmitted(false)
            console.log(error)
        }
    }

    const handleAddComment = async ( highFiveId, comment, userId ) => {
        const data = {
            comment: comment,
            userId: userId,
        }

        try {
            await authAxios.post(`${highFiveCommentBaseURL}/${highFiveId}`, data)
            setHighfivesUpdated(true)
        } catch (error) {
            console.log(error)
        }
    }

    const checkIfUserLiked = (array, id, highFiveId, value) => {
        let liked = false
        array.forEach((item) => {
            if (item.highFiveId === highFiveId && item.userId === id && item.value === value) {
                liked = true
            }
        })
        return liked
    }

    const handleValueLike = async (id, value, userId) => {
        const data = {
            value: value,
            userId: userId,
        }

        try {
            await authAxios.post(`${valueLikeBaseURL}/${id}`, data)
            setHighfivesUpdated(true)
        } catch (error) {
            console.log(error)
        }
    }

    const clearHighFive = () => {
        setInitialUsers([])
        setSelectedUsers([])
        setHighFiveFormData({...highFiveFormData, highFiveMessage:''}) 
        setHighFiveSubmitted(null)
        setHighfivesUpdated(false)
    }

    return (
        <HighFiveContext.Provider 
            value={{
                searchValue,
                allHighFives, 
                setAllHighFives, 
                givenHighFives, 
                setGivenHighFives, 
                receivedHighFives, 
                setReceivedHighFives, 
                viewingAll, 
                setViewingAll, 
                usersRecords, 
                setUsersRecords, 
                initialUsers, 
                setInitialUsers, 
                selectedUsers, 
                setSelectedUsers, 
                highFiveFormData,
                highFiveSubmitted, 
                setHighFiveSubmitted, 
                highfivesUpdated,
                // functions
                highfiveEmailLoop,
                getAllHighFives,
                initializeUserInfo,
                toggleHighFiveView,
                filterList,
                toggleUsersInList,
                addExternalRecipient,
                highFiveOnChange,
                submitHighFive,
                handleAddComment,
                checkIfUserLiked,
                handleValueLike,
                clearHighFive
            }}>
            { children }
        </HighFiveContext.Provider>
    )
}
