import SemiCircle from '../../../Assets/images/Public-Website/design/SemiCircle'

const ImageHero = ({ data }) => {
    return (
        <header className={`header-hero header-hero-background-${data.layout}`}>
            <SemiCircle className='header-hero-semicircle' />
            <div className='gelcoat-top'></div>
            <div className='gelcoat-left'></div>
            <div className='header-hero-content'>
                <h1 className='text-hero'>{data.title}</h1>
                <p className='text-title'>{data.caption}</p>
                {data.link && 
                    <a href={data.link}>
                        <button className='salmon-button'>
                            {data.linkName}
                        </button>
                    </a>
                }
            </div>
        </header>
    )
}

export default ImageHero
