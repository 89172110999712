import { useEffect, useState } from "react"
import axios from "axios"
import SemiCircleHeader from "../../Communities/PublicWebsite/Shared/SemiCircleHeader"

const NotFound404 = () => {
	const [randomDogPicture, setRandomDogPic] = useState("")

	useEffect(() => {
		let isSubscribed = true
		const getRandomDogPicture = async () => {
			await axios
				.get(`https://random.dog/woof.json`)
				.then((res) => {
					isImageVideo(res.data.url)
				})
				.catch((err) => {
					throw err
				})
		}

		const isImageVideo = (image) => {
			if (image.includes(".mp4")) {
				getRandomDogPicture()
			} else {
				if(isSubscribed) setRandomDogPic(image)
			}
		}
		getRandomDogPicture();
		return () => isSubscribed = false
	}, [])

	

	return (
		<main className="not-found-wrap sprout-typography" data-test="not-found-page-body">
			<SemiCircleHeader headerText='404' />
			<h1 className="not-found-page-title">
				{" "}
				Oops! That page doesnt exist. Sorry about that!{" "}
			</h1>
			<h2 className="not-found-text-option-title">
				{" "}
				Here are a few things you can do:{" "}
			</h2>
			<div className="not-found-text-option-container">
				<h3 className="not-found-text-option">
					{" "}
					Try starting at the home page again by clicking on the
					InsideOut Development logo in the footer below{" "}
				</h3>
				<h3 className="not-found-text-option">
					{" "}
					Let our IT department know what happened by emailing
					<a href="mailto:itsupport@insideoutdev.com">
						{" "}
						itsupport@insideoutdev.com{" "}
					</a>
				</h3>
				<h3 className="not-found-text-option">
					{" "}
					Forget your frustrations and look at this dog instead
					(refresh if you want another!){" "}
				</h3>
				<img
					src={randomDogPicture}
					alt="Random Dog"
					data-test="not-found-page-random-dog-image"
					className="not-found-page-random-dog-image"
				/>
			</div>
		</main>
	)
}

export default NotFound404
