import coachingCertificationAgendaPdf from './Coaching_Certification_Agenda.pdf'

const CoachingCertificationAgenda = () => {

	return (
		<div style={{width: '100%'}}>
			<iframe 
				src={coachingCertificationAgendaPdf} 
				style={{width:'100%', height: '1000px'}} 
				title='Coaching Event Guide' >
			</iframe>
		</div>
	);
};

export default CoachingCertificationAgenda;
