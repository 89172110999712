import ThreeM from '../../../Assets/images/Public-Website/customer-logos/3M.jpg'
import AAA_Arizona from '../../../Assets/images/Public-Website/customer-logos/aaa-arizona.jpg'
import American_Red_Cross from '../../../Assets/images/Public-Website/customer-logos/american-red-cross.jpg'
import AmFam from '../../../Assets/images/Public-Website/customer-logos/amfam.jpg'
import Andersen from '../../../Assets/images/Public-Website/customer-logos/andersen.jpg'
// Apple
import Booz_Allen_Hamilton from '../../../Assets/images/Public-Website/customer-logos/booz-allen-hamilton.jpg'
import BuffaloWildWings from '../../../Assets/images/Public-Website/customer-logos/buffalo-wild-wings.jpg'
import CanonUSA from '../../../Assets/images/Public-Website/customer-logos/canon.jpg'
import CapitalBlueCross from '../../../Assets/images/Public-Website/customer-logos/capital-bluecross.jpg'
import ChryslerCorp from '../../../Assets/images/Public-Website/customer-logos/chrysler-corp.jpg'
import EatonVance from '../../../Assets/images/Public-Website/customer-logos/eaton-vance.jpg'
import FDA from '../../../Assets/images/Public-Website/customer-logos/FDA.jpg'
import GulfportEnergy from '../../../Assets/images/Public-Website/customer-logos/gulfport-energy.jpg'
import Hilti from '../../../Assets/images/Public-Website/customer-logos/hilti.jpg'
import HoraceMann from '../../../Assets/images/Public-Website/customer-logos/horace-mann.jpg'
import Humana from '../../../Assets/images/Public-Website/customer-logos/humana.jpg'
import KaiserPermanente from '../../../Assets/images/Public-Website/customer-logos/kaiser-permanente.jpg'
import Kraft from '../../../Assets/images/Public-Website/customer-logos/kraft.jpg'
import Levi_Strauss from '../../../Assets/images/Public-Website/customer-logos/levi-strauss-and-co.jpg'
import LockheedMartin from '../../../Assets/images/Public-Website/customer-logos/lockheed-martin.jpg'
import Macys from '../../../Assets/images/Public-Website/customer-logos/macys.jpg'
import NCCI from '../../../Assets/images/Public-Website/customer-logos/ncci-holdings.jpg'
import Netsurion from '../../../Assets/images/Public-Website/customer-logos/netsurion.jpg'
import NorthernTool from '../../../Assets/images/Public-Website/customer-logos/northerntool.jpg'
import OCTanner from '../../../Assets/images/Public-Website/customer-logos/oc-tanner.jpg'
import OmniCable from '../../../Assets/images/Public-Website/customer-logos/omni-cable.jpg'
import Oracle from '../../../Assets/images/Public-Website/customer-logos/oracle.jpg'
import Peterbilt from '../../../Assets/images/Public-Website/customer-logos/peterbilt.jpg'
import SonyElectronics from '../../../Assets/images/Public-Website/customer-logos/sony.jpg'
import GoodyearTire from '../../../Assets/images/Public-Website/customer-logos/goodyear-tire.jpg'
import TCFBank from '../../../Assets/images/Public-Website/customer-logos/TCF-bank.jpg'
import United_Healthcare from '../../../Assets/images/Public-Website/customer-logos/united-healthcare.jpg'
import UWBusinessSchool from '../../../Assets/images/Public-Website/customer-logos/uw-school-of-business.jpg'
import SkyworksSolutions from '../../../Assets/images/Public-Website/customer-logos/skyworks.jpg'
import WinrockIntl from '../../../Assets/images/Public-Website/customer-logos/winrock-intl.jpg'


export const ClientLogosData = [
    {
        logo: ThreeM,
        alt: '3M'
    },
    {
        logo: AAA_Arizona,
        alt: 'AAA Arizona'
    }, 
    {
        logo: American_Red_Cross,
        alt: 'American Red Cross'
    }, 
    {
        logo: AmFam,
        alt: 'AmFam'
    }, 
    {
        logo: Andersen,
        alt: 'Andersen'
    }, 
    {
        logo: Booz_Allen_Hamilton,
        alt: 'Booz Allen Hamilton'
    }, 
    {
        logo: BuffaloWildWings,
        alt: 'Buffalo Wild Wings'
    },
    {
        logo: CanonUSA,
        alt: 'Canon USA'
    },
    {
        logo: CapitalBlueCross,
        alt: 'Capital Blue Cross'
    },
    {
        logo: ChryslerCorp,
        alt: 'Chrysler Corp.'
    },
    {
        logo: EatonVance,
        alt: 'Eaton Vance'
    },
    {
        logo: FDA,
        alt: 'FDA'
    },
    {
        logo: GulfportEnergy,
        alt: 'Gulfport Energy'
    },
    {
        logo: Hilti,
        alt: 'Hilti'
    },
    {
        logo: HoraceMann,
        alt: 'Horace Mann'
    },
    {
        logo: Humana,
        alt: 'Humana'
    },
    {
        logo: KaiserPermanente,
        alt: 'Kaiser Permanente'
    },
    {
        logo: Kraft,
        alt: 'Kraft'
    },
    {
        logo: Levi_Strauss,
        alt: 'Levi Strauss and Co'
    }, 
    {
        logo: LockheedMartin,
        alt: 'Lockheed Martin'
    },
    {
        logo: Macys,
        alt: 'Macys'
    }, 
    {
        logo: NCCI,
        alt: 'NCCI Holdings'
    },
    {
        logo: Netsurion,
        alt: 'Netsurion'
    },
    {
        logo: NorthernTool,
        alt: 'Northern Tool'
    },
    {
        logo: OCTanner,
        alt: 'OC Tanner'
    },
    {
        logo: OmniCable,
        alt: 'Omni Cable'
    },
    {
        logo: Oracle,
        alt: 'Oracle'
    },
    {
        logo: Peterbilt,
        alt: 'Peterbilt'
    },
    {
        logo: SonyElectronics,
        alt: 'Sony Electronics'
    },
    {
        logo: GoodyearTire,
        alt: 'Goodyear Tire'
    },
    {
        logo: TCFBank,
        alt: 'TCF Bank'
    },
    {
        logo: United_Healthcare,
        alt: 'United Healthcare'
    },
    {
        logo: UWBusinessSchool,
        alt: 'UW Business School'
    },
    {
        logo: SkyworksSolutions,
        alt: 'Skyworks Solutions'
    },
    {
        logo: WinrockIntl,
        alt: 'Winrock Intl.'
    }
]
