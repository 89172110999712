import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'

const baseURL = '/v1/api/global/features'
const FeaturesContext = React.createContext()

class FeaturesProvider extends Component {
    constructor(){
        super()
        this.state = {
            allFeatures: [],
            resolvedFeatures: [],
            unresolvedFeatures: [],
            selectedFeature: {},
            success: null,
            error: null
        }
    }

    getAllFeatures = () => {
        authAxios
            .get(`${baseURL}/`)
            .then(res => {
                this.setState({ allFeatures: res.data });
            })
            .catch( err => {throw err } );
    }

    getOneFeature = (id) => {
        authAxios
        .get(`${baseURL}/${id}`)
        .then(res => {
            this.setState({ selectedFeature: res.data })
        })
        .catch( err => {throw err} );
    }

    postNewFeature = (formData) => {
        authAxios
        .post(`${baseURL}/`, formData)
        .then(() => {
            this.setState({ error: '' })
            this.setState({ success: 'Feature report has been successfully created!' })
            setTimeout(function(){
                this.setState({success:''});
            }.bind(this),3000)
        })
        .then(() => {this.getAllFeatures()})
        .catch( err => {
            this.setState({ error: err })  
            throw err
        });
    }

    deleteFeature = (id) => {
        authAxios
        .delete(`${baseURL}/${id}`)
        .then(() => {
            this.setState({ success: 'Feature report has been successfully deleted.' })
        })
        .catch( err => this.setState({ error: err }) );
    }

    editFeature = (id, formData) => {
        authAxios
        .put(`${baseURL}/${id}`, formData)
        .then(() => {
            this.setState({ success: 'Feature report has been successfully updated.' })
        })
        .catch( err => this.setState({ error: err }) );
    }

    markComplete = (id, isComplete) => {
        authAxios
        .put(`${baseURL}/${id}`, {isComplete: isComplete})
        .then(() => {
            this.setState({ success: 'Feature Completed!'})
            setTimeout(function(){
                this.setState({success:''});
            }.bind(this),3000)
        })
        .then(() => {this.getAllFeatures()})
        .catch( err => this.setState({ error: err }) );
    }

    render(){
        return (
            <FeaturesContext.Provider 
                value={{
                    ...this.state,
                    getAllFeatures : this.getAllFeatures, 
                    getOneFeature : this.getOneFeature,
                    postNewFeature : this.postNewFeature,
                    deleteFeature : this.deleteFeature,
                    editFeature : this.editFeature,
                    markComplete: this.markComplete
                }}>
                { this.props.children }
            </FeaturesContext.Provider>
        )
    }
}

export default FeaturesProvider

export const withFeatures = C => props => (
    <FeaturesContext.Consumer>
        {value => <C {...props} {...value}/>}
    </FeaturesContext.Consumer>
)