import { useState } from 'react'
import { storyblokEditable } from "@storyblok/react"
import { Link } from 'react-router-dom'

const StoryblokResourceCard = ({ blok }) => {
    const [showingCardDetails, setShowingCardDetails] = useState(false)


    return (
        <Link to={`/${blok.link.cached_url}`} className='coachingLibrary-card' {...storyblokEditable(blok)} onMouseOver={() => setShowingCardDetails(true)} onMouseLeave={() => setShowingCardDetails(false)}>
            <img src={blok.thumbnail.filename} alt={blok.thumbnail.alt} loading='lazy'  />
            <div className='coachingLibrary-card-content'>
                <strong className='text-caption'> {blok.displayType} </strong>
                <h4 className='text-caption'> {blok.title} </h4>
            </div>

            <div className={showingCardDetails ? 'coachingLibrary-card-description' : 'hidden'}>
                <p style={{textOverflow: 'ellipsis'}}> {blok.caption} </p>
                <div className={showingCardDetails ? 'coachingLibrary-card-description-cta' : 'hidden'}>
                    <button>{blok.cta.toUpperCase()}</button>
                </div>
            </div>
        </Link>
    )
}

export default StoryblokResourceCard
