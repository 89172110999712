import { storyblokEditable } from "@storyblok/react"
import SemiCircleHeader from "../../Communities/PublicWebsite/Shared/SemiCircleHeader"

const StoryblokHeader = ({ blok }) => {
    if(blok.style === 'semi-circle'){
        return (
            <SemiCircleHeader headerText={blok.headline} {...storyblokEditable(blok)} />
        )
    } else {
        return (
            <h2 style={{textAlign: 'center'}} {...storyblokEditable(blok)} >{blok.headline}</h2>
        )
    }
}

export default StoryblokHeader
