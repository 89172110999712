import { useEffect } from "react"

const ContactBanner = ({ title }) => {

    useEffect(() => {
        const script = document.createElement('script')
        script.src='//js.hsforms.net/forms/v2.js?pre=1'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "4127087",
                    formId: "d8cbab00-a2ae-441e-8da9-ac89b98da001",
                    target: '#hubspotContactBannerForm'
                })
            }
        })
    }, [])

    return (
        <section className='contact-banner' id='contact'>
            <article className='contact-banner-content'>
                <h2 className='contact-banner-content-header'>
                    {title}
                </h2>
                <p className='contact-banner-content-subheader text-body-lg'> 
                    Get in touch with a member of our coaching staff to talk
                </p>
                <p className='contact-banner-content-subheader text-body-lg'> 
                    about solutions for your organization.
                </p>
                <div id="hubspotContactBannerForm"></div>
            </article>
        </section>
    )
}

export default ContactBanner
