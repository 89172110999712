
export const highfiveEmail = ({ emailRecipient, iodFrontEndUrl, emailSender, highfiveMessage, agreements }) => {

    const employeeLinkBack = emailRecipient.isEmployee ? `Click this link check out all of the <a style="color: #005295; font-size: 17px; font-weight: bold;" href="${iodFrontEndUrl}/a2b/pulse/highfives" target="_blank">Highfives</a>` : 'Thank you for helping make InsideOut Development great!'

    // const agreement1 = agreements.includes('agreement1') ? `<li style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Be Impeccable with Your Word</li>` : ''
    // const agreement2 = agreements.includes('agreement2') ? `<li style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Don't Take Anything Personally</li>` : ''
    // const agreement3 = agreements.includes('agreement3') ?`<li style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Don't Make Assumptions</li>` : ''
    // const agreement4 = agreements.includes('agreement4') ? `<li style="color: rgb(79, 79, 79); font-size: 17px; font-weight: 300;">Always Do Your Best</li>`: ''

    // const valueGiven = agreements.length > 0 ? `
    //     <p style="color: rgb(79, 79, 79);">You're recognized for personifying these InsideOut Development values:</p>
    //     <ul>
    //         ${agreement1} 
    //         ${agreement2} 
    //         ${agreement3} 
    //         ${agreement4}
    //     </ul>
    // ` : ''


    return (
        `
        <head>
            <style>
                body {
                    margin: 16px; 
                    font-family: Arial, sans-serif;
                }
                
                header {
                    background-color: lightgray;
                    padding: 5px;
                }
                article {
                    padding: 5px
                }
                h2 {
                    color: #005295; 
                    font-size: 22px; 
                    font-weight: bold; 
                    margin: 0px;
                }
                h3 {
                    color: #4D4D4D; 
                    font-size: 18px; 
                    font-weight: bold; 
                    margin: 0px 0px 14px 0px;
                }
                ul {
                    list-style-type: circle;
                }
                li {
                    margin-left: 2px;
                }
                p {
                    font-size: 17px; 
                    font-weight: 300;
                }
                footer {
                    width: 100%;
                    height: 60px;
                    background: #5892C4;
                    position: relative;
                    margin-top: 10px;
                }
            </style>
        </head>
        <body>
            <header>
                <h2> InsideOut Development </h2>
                <h3> You've been given a High Five! </h3>
            </header>
            <article>
                <p style="color: rgb(79, 79, 79);">You received a High Five from ${emailSender}!</p>
                <p style="color: #4D4D4D;">"${highfiveMessage}"</p>

                
                <p style="color: rgb(79, 79, 79);">${employeeLinkBack}</p>
            </article>
            <footer></footer>
        </body>`
    )
}

export default highfiveEmail
