/* eslint-disable no-undef */
const dotenv = require('dotenv');
let stripeKey;
let iodFrontEndUrl;
let strapiEndpoint;
let logrocketId;
let registerDomain;
let appDomain;


dotenv.config();
if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'development'){
    stripeKey = process.env.REACT_APP_STRIPE_TEST_API_KEY
    iodFrontEndUrl = 'http://localhost:3000'
    strapiEndpoint = 'http://localhost:1337'
    logrocketId = 'tqx5ak/testing-zs3ei'
    registerDomain = 'https://register-dev.insideoutdev.com'
    appDomain = 'https://app-dev.insideoutdev.com'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'test'){
    stripeKey = process.env.REACT_APP_STRIPE_TEST_API_KEY
    iodFrontEndUrl = 'https://dev.insideoutdev.com'
    strapiEndpoint = 'https://dev.insideoutdev.com/strapi'
    logrocketId = 'tqx5ak/testing-zs3ei'
    registerDomain = 'https://register-dev.insideoutdev.com'
    appDomain = 'https://app-dev.insideoutdev.com'
} else if(process.env.REACT_APP_CUSTOM_NODE_ENV === 'production'){
    stripeKey = process.env.REACT_APP_STRIPE_LIVE_API_KEY
    iodFrontEndUrl = `https://insideoutdev.com`
    strapiEndpoint = 'https://insideoutdev.com/strapi'
    logrocketId = 'tqx5ak/iod-rebuild-production'
    registerDomain = 'https://register.insideoutdev.com'
    appDomain = 'https://app.insideoutdev.com'
}

module.exports = {
    IOCT3PriceString : process.env.REACT_APP_IOCT3_PRICE,
    T3PriceString : process.env.REACT_APP_T3_PRICE,
    IOCPriceString : process.env.REACT_APP_IOC_PRICE,
    breakthroughsPriceString : process.env.REACT_APP_BREAKTHROUGHS_PRICE,
    stripeAPIKey: stripeKey,
    iodFrontEndUrl: iodFrontEndUrl,
    strapiEndpoint: strapiEndpoint,
    logrocketId: logrocketId,
    nodeEnvironment: process.env.REACT_APP_CUSTOM_NODE_ENV,
    googleAnalyticsTag: process.env.REACT_APP_GOOGLE_ANALYTICS_TAG,
    adobeConnectWorkshopLink: 'http://insideout.adobeconnect.com/iocallday/',
    adobeConnectCertificationLink: 'http://insideout.adobeconnect.com/ioccert/',
    emailSenderAddress: process.env.REACT_APP_MAILER_ADDRESS,
    registerDomain: registerDomain,
    appDomain: appDomain
}
