
const SemiCircle = ({ className }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 186.86 377"
            className={className}
        >
            <path 
                d="M5,377H0V0H5c3,.66,6,1.77,9.09,1.92C37.66,3.08,59.91,9.44,81,19.58a185.52,185.52,0,0,1,64.27,51c27,33.27,40.14,71.54,41.51,113.93.38,11.67-1.12,23.51-2.83,35.12-5.69,38.76-23.29,71.76-50.17,99.89a179.74,179.74,0,0,1-37,29.32A185.5,185.5,0,0,1,20.6,375C15.39,375.62,10.2,376.34,5,377Z" 
            />
        </svg>
    )
}

export default SemiCircle