
const SuccessStoryCard = ({ name, image, caption, displayClass, logo }) => {
    return (
        <section className={`carousels-case-studies-container-map-card ${displayClass}`}>
            <img src={image} alt={name} className='carousels-case-studies-container-map-card-thumbnail' />
            <div className='carousels-case-studies-container-map-card-content'>
                <strong className='text-body'>{name}</strong>
                <blockquote className='text-body-sm'>{caption}</blockquote>
                <img src={logo} alt={name} className='carousels-case-studies-container-map-card-content-logo'/>
            </div>
        </section>
    )
}

export default SuccessStoryCard
