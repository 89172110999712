import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { topicsData, rolesData, typesData } from './ResourceFilterData'
// only used in ResourceFilterBar

const ResourceFilterDropdown = ({ data, showing, displayMobile }) => {
    return (
        <ul style={{visibility: showing ? 'visible' : 'hidden'}} className='coachingLibrary-filterbar-filters-item-dropdown'>
            {data.map(item => (
                <li key={item.name}>
                    <NavLink 
                        to={`/resources/${item.value}`}
                        className='coachingLibrary-link'
                        activeClassName='coachingLibrary-link-active'
                    >
                        <span>
                            <p>{item.name}</p>  
                            {displayMobile &&
                                <FontAwesomeIcon icon={"chevron-right"} />
                            }
                        </span>
                    </NavLink>
                </li>
            ))}
        </ul>
    )
}


function ResourceFilter({ closeMobileFilter, displayMobile }) {
    const [showingTopics, setShowingTopics] = useState(false)
    const [showingRoles, setShowingRoles] = useState(false)
    const [showingTypes, setShowingTypes] = useState(false)

    return (
        <>
            {displayMobile &&
            <>
                <div className='coachingLibrary-filterbar-overlay'></div>
                <div className='coachingLibrary-filterbar-filters-header'>
                    <button onClick={closeMobileFilter}>
                        <FontAwesomeIcon icon="times-circle" />
                    </button>
                    <p className='text-header'>CATEGORIES</p>
                </div>
            </>
            }

            <section className='coachingLibrary-filterbar-filters'>
                <NavLink 
                    to='/resources/coaching-library-home'
                    className='coachingLibrary-link'
                    activeClassName='coachingLibrary-link-active'
                >
                    <span className='coachingLibrary-filterbar-filters-item'>
                        <p className='text-subheader'>Explore All</p>
                        {displayMobile &&
                            <FontAwesomeIcon icon={"chevron-right"} />
                        }
                    </span>
                </NavLink>


                <div {...(!displayMobile && {onMouseLeave: () => setShowingTopics(false)})}>
                    <span {...(!displayMobile && {onMouseOver: () => setShowingTopics(true)})}
                    onClick={() => setShowingTopics(!showingTopics)} className='coachingLibrary-filterbar-filters-item'>
                        <p className='text-subheader'>By Topic</p>
                        <FontAwesomeIcon 
                            icon={displayMobile && !showingTopics ? "plus" : displayMobile ? "minus" : "caret-down"} 
                            style={{marginLeft:'5px'}} 
                        />
                    </span>
                    {showingTopics &&
                        <ResourceFilterDropdown data={topicsData} showing={showingTopics} displayMobile={displayMobile} />
                    }
                </div>

                <div {...(!displayMobile && {onMouseLeave: () => setShowingRoles(false)})}>
                    <span {...(!displayMobile && {onMouseOver: () => setShowingRoles(true)})} onClick={() => setShowingRoles(!showingRoles)} className='coachingLibrary-filterbar-filters-item'>
                        <p className='text-subheader'>By Role</p>
                        <FontAwesomeIcon 
                            icon={displayMobile && !showingRoles ? "plus" : displayMobile ? "minus" : "caret-down"} 
                            style={{marginLeft:'5px'}} 
                        />
                    </span>
                    {showingRoles &&
                        <ResourceFilterDropdown data={rolesData} showing={showingRoles} displayMobile={displayMobile} />
                    }
                </div>

                <NavLink
                    to='/resources/our-solutions'
                    className='coachingLibrary-link'
                    activeClassName='coachingLibrary-link-active'
                >
                    <span className='coachingLibrary-filterbar-filters-item'>
                        <p className='text-subheader'>By Solution</p>
                        {displayMobile &&
                            <FontAwesomeIcon icon={"chevron-right"} />
                        }
                    </span>
                </NavLink>

                <div {...(!displayMobile && {onMouseLeave: () => setShowingTypes(false)})}>
                    <span {...(!displayMobile && {onMouseOver: () => setShowingTypes(true)})} onClick={() => setShowingTypes(!showingTypes)} className='coachingLibrary-filterbar-filters-item'>
                        <p className='text-subheader'>By Type</p>
                        <FontAwesomeIcon 
                            icon={displayMobile && !showingTypes ? "plus" : displayMobile ? "minus" : "caret-down"} 
                            style={{marginLeft:'5px'}} 
                        />
                    </span>
                    {showingTypes &&
                        <ResourceFilterDropdown data={typesData} showing={showingTypes} displayMobile={displayMobile} />
                    }
                </div>
            </section>
        </>
    )
}

export default ResourceFilter