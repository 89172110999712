import React, { Component } from 'react'
import { authAxios } from '../shared/CustomAxios'

const baseURL = '/v1/ioc/campaign-members'
const CampaignMembersContext = React.createContext()

class CampaignMembersProvider extends Component {
    constructor(){
        super()
        this.state = {
            allCampaignMembers: [],
            campaignMembersWithSelfAssessment: [],
            campaignMembersWithoutSelfAssessment: [],
        }
    }

    getAllCampaignMembers = (id) => {
        let url = baseURL
        if(id){ url = `${baseURL}?campaignId=${id}`}

        authAxios
            .get(`${url}`)
            .then(res => {
                this.setState({ allCampaignMembers: res.data });
            })
            .catch( err => {throw err } );
    }

    getCampaignMembersWithSelfAssessment = (id) => {
        let url = `${baseURL}?IocPreworkComplete=1`
        if(id){ url = `${url}&campaignId=${id}`}

        authAxios
            .get(`${url}`)
            .then(res => {
                this.setState({ campaignMembersWithSelfAssessment: res.data });
            })
            .catch( err => {throw err } );
    }

    getCampaignMembersWithoutSelfAssessment = (id) => {
        let url = `${baseURL}?IocPreworkComplete=0`
        if(id){ url = `${url}&campaignId=${id}`}

        authAxios
            .get(`${url}`)
            .then(res => {
                this.setState({ campaignMembersWithoutSelfAssessment: res.data });
            })
            .catch( err => {throw err } );
    }

    
    clearCampaignMembers = () => {
        this.setState({ allCampaignMembers: [], campaignMembersWithSelfAssessment: [], campaignMembersWithoutSelfAssessment: [] })
    }


    render(){
        return (
            <CampaignMembersContext.Provider 
                value={{
                    ...this.state,
                    getAllCampaignMembers : this.getAllCampaignMembers, 
                    getCampaignMembersWithSelfAssessment: this.getCampaignMembersWithSelfAssessment,
                    getCampaignMembersWithoutSelfAssessment: this.getCampaignMembersWithoutSelfAssessment,
                    clearCampaignMembers: this.clearCampaignMembers
                }}>
                { this.props.children }
            </CampaignMembersContext.Provider>
        )
    }
}

export default CampaignMembersProvider

export const withCampaignMembers = C => props => (
    <CampaignMembersContext.Consumer>
        {value => <C {...props} {...value}/>}
    </CampaignMembersContext.Consumer>
)