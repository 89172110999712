import Testimonial1 from '../../../Assets/images/Public-Website/stock/collaboration-stock.jpg'
import Testimonial2 from '../../../Assets/images/Public-Website/stock/success-stock.jpg'
import Testimonial3 from '../../../Assets/images/Public-Website/stock/interview-stock.jpg'
import Hilti from '../../../Assets/images/Public-Website/customer-logos/hilti.jpg'
import KaiserPermanente from '../../../Assets/images/Public-Website/customer-logos/kaiser-permanente.jpg'
import Logicalis from '../../../Assets/images/Public-Website/customer-logos/logicalis.jpg'

export const SuccessStoriesData = [
    {
        image: Testimonial1,
        name: 'Kaiser Permanente',
        caption: <>I have been passionate about InsideOut since first being introduced to it in 2005. The model is simple, straightforward, and relevant. The application of the GROW<sup>®</sup> model sticks.</>,
        logo: KaiserPermanente
    },
    {
        image: Testimonial2,
        name: 'Logicalis',
        caption: 'Logicalis Group improved manager-employee conversations by more than 75%, retention by 50-75%, and internal promotions by 50-75% since working with InsideOut Development.',
        logo: Logicalis
    },
    {
        image: Testimonial3,
        name: 'Hilti Corp',
        caption: 'Hilti Corp. improved manager-employee in-the-moment conversations as well as planned conversations, which allows frontline managers to have better engaged discussions with their people.',
        logo: Hilti
    }
]

export const FormatData = [
    {
        title: 'Digital On Demand',
        description: 'Learn at your own pace while experiencing all the benefits of GROW®',
        backgroundImage: 'digital'
    },
    {
        title: 'Live Online',
        description: 'Led by professionally trained facilitators with a variety of learning options',
        backgroundImage: 'live-online'
    },
    {
        title: 'Traditional Classroom',
        description: 'Led by best in class professionally trained facilitators',
        backgroundImage: 'traditional'
    },
]
