import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BlockquoteSectionIcons = ({ quote }) => {
    return (
        <section className='blockquote-large'>
            <FontAwesomeIcon icon={"quote-left"} className='blockquote-large-quote-left' />
            <blockquote className='h3 blockquote-large-quote'>
                {quote}
            </blockquote>
            <FontAwesomeIcon icon={"quote-left"} className='blockquote-large-quote-right' />
        </section>
    )
}

export default BlockquoteSectionIcons
