import CityImage from '../../../Assets/images/Public-Website/semi-circle-sections/our-office.jpg'

const OurOffice = () => {
    return (
        <section className='our-office' id='our-office'>
            <h2>Our Office</h2>
            <div className='columns-lg text-body-lg'>
                <img src={CityImage} alt="InsideOut Development Headquarters" loading='lazy' className='img-section col'/>
                <address className='col'>
                    <span>
                        <p>InsideOut Development, LLC</p>
                        <p>270 N Main St Suite B</p>
                        <p>Alpine, UT 84004</p>
                    </span>
                    <span>
                        <a href='tel:1-888-262-2448'>T: 1.888.262.2448</a>
                        <a href='fax:+1.801.253.4135'>F: 1.801.253.4135</a>
                        <a href="mailto:info@insideoutdev.com">E: info@insideoutdev.com</a>
                    </span>
                </address>
            </div>
        </section>
    )
}

export default OurOffice
