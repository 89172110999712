import React, { Component } from 'react'
import axios from 'axios'

const baseURL = '/v1/iod/events'
const EventsContext = React.createContext()

class EventsProvider extends Component {
    constructor(){
        super()
        this.state = {
            upcomingEvents: [],
            allUpcomingBreakthroughEvents: [],
            allUpcomingWebinarEvents: [],
            allUpcomingOnTheRoadEvents: [],
            selectedEvent: {},
            IocRegistration1DayEvent: {},
            IocT3Registration2DayEvent: {},
            IocAndT3Registration3DayEvent: {},
            location: null,
            startDate : null,
            endDate: null,
            webinarCheckbox: '',
            workshopCertificationCheckbox: '',
            inPersonCheckbox: '',
            onlineCheckbox: '',
            // set but currently unused
            upcomingEventsNA: [],
            upcomingEventsAPAC: [],
            upcomingEventsEMEA: [],
            // used in iocdf sessions?
            allIocEvents: []
        }
    }

    clearCachedEvent = () => {
        this.setState({selectedEvent: {},
            IocRegistration1DayEvent: {},
            IocT3Registration2DayEvent: {},
            IocAndT3Registration3DayEvent: {},
            location: null,
            startDate : null,
            endDate: null,
            webinarCheckbox: '',
            workshopCertificationCheckbox: '',
            inPersonCheckbox: '',
            onlineCheckbox: '' 
        })
    }


    handleEventsChange = (e) => {
        const { name, value } = e.target

        if(e.target.type === 'checkbox'){
            if(name === 'inPersonCheckbox') this.setState({ onlineCheckbox: '' })
            if(name === 'onlineCheckbox') this.setState({ inPersonCheckbox: '' })
            if(name === 'webinarCheckbox') this.setState({ workshopCertificationCheckbox: '' })
            if(name === 'workshopCertificationCheckbox') this.setState({ webinarCheckbox: '' })
            this.setState({ [name]: !this.state[name]})
        } else {
            this.setState({ [name]: value })
        }
    }

    getAllUpcomingGrowEvents = async (query) => {
        let url = `${baseURL}/upcoming/grow?`
        if(query) {
            if(query.State__c !== null && query.State__c !== "" )  {
                url = `${url}State__c=${query.State__c}&`
            }
            if(query.StartDate)  {
                url = `${url}StartDate=${query.StartDate}&`
            }
            if(query.EndDate)  {
                url = `${url}EndDate=${query.EndDate}`
            }
        }

        try {
            const { data } = await axios.get(url)
            let naEvents = data.filter(event => event.Region__c === 'Americas')
            let apacEvents = data.filter(event => event.Region__c === 'APAC')
            let emeaEvents = data.filter(event => event.Region__c === 'EMEA')

            this.setState({ upcomingEvents: data, upcomingEventsNA: naEvents, upcomingEventsAPAC: apacEvents, upcomingEventsEMEA: emeaEvents });
        } catch(error) {
            console.log(error)
        }
    }

    getAllUpcomingIOCEvents = async (query) => {
        let url = `${baseURL}/upcoming/ioc?`
        if(query) {
            if(query.State__c !== null && query.State__c !== "" )  {
                url = `${url}State__c=${query.State__c}&`
            }
            if(query.StartDate)  {
                url = `${url}StartDate=${query.StartDate}&`
            }
            if(query.EndDate)  {
                url = `${url}EndDate=${query.EndDate}`
            }
        }

        try {
            const { data } = await axios.get(url)
            let naEvents = data.filter(event => event.Region__c === 'Americas')
            let apacEvents = data.filter(event => event.Region__c === 'APAC')
            let emeaEvents = data.filter(event => event.Region__c === 'EMEA')

            this.setState({ upcomingEvents: data, upcomingEventsNA: naEvents, upcomingEventsAPAC: apacEvents, upcomingEventsEMEA: emeaEvents });
        } catch(error) {
            console.log(error)
        }
    }

    // Filter out if event is less than 2 weeks away
    getAllUpcomingBreakthroughEvents = (query) => {
        let url = `${baseURL}/upcoming/breakthroughs?`
        if(query) {
            if(query.StartDate)  {
                url = `${url}StartDate=${query.StartDate}&`
            }
            if(query.EndDate)  {
                url = `${url}EndDate=${query.EndDate}`
            }
        }
        axios
        .get(url)
        .then(res => {
            this.setState({ allUpcomingBreakthroughEvents: res.data });
        })
        .catch( err => {throw err} );
    }

    getAllUpcomingWebinarEvents = (query) => {
        let url = `${baseURL}/upcoming/webinars?`
        if(query) {
            if(query.StartDate)  {
                url = `${url}StartDate=${query.StartDate}&`
            }
            if(query.EndDate)  {
                url = `${url}EndDate=${query.EndDate}`
            }
        }
        axios
        .get(url)
        .then(res => {
            this.setState({ allUpcomingWebinarEvents: res.data });
        })
        .catch( err => {throw err} );
    }

    getOneEvent = (id) => {
        axios
        .get(`${baseURL}/${id}`)
        .then(res => {
            let data = res.data[0]
            this.setState({ selectedEvent: data })
            this.getChildrenEventsOfParentEvent(data.Id)
        })
        .catch( err => {  
            throw err
        });
    }

    clearSelectedEvent = () => {
        this.setState({ selectedEvent: {} })
    }

    getChildrenEventsOfParentEvent = (id) => {
        axios
        .get(`${baseURL}/${id}/children`)
        .then(res => {
            const availableChildren = res.data.filter(event => event.Trigger_Overflow__c !== true)
            availableChildren.forEach(childEvent => {
                if(childEvent.Campaign_Class__c === 'IOC and T3 Registration' || childEvent.Campaign_Class__c === 'GROW Coaching and T3 Registration'){
                    this.setState({ IocAndT3Registration3DayEvent: childEvent })
                }
                if(childEvent.Campaign_Class__c === 'IOC Registration' || childEvent.Campaign_Class__c === 'GROW Coaching Registration'){
                    this.setState({ IocRegistration1DayEvent: childEvent })
                }
                if(childEvent.Campaign_Class__c === 'IOCT3 Registration' || childEvent.Campaign_Class__c === 'GROW Coaching T3 Registration'){
                    this.setState({ IocT3Registration2DayEvent: childEvent })
                }
            })
        })
        .catch( err => {throw err} );
    }

    resyncEvents = async () => {
        try {
            let res = await axios.get(`${baseURL}/resync-events`)
            console.log(res.data.msg)
        } catch (error) {
            console.log(error)
        }
    }


    render(){
        return (
            <EventsContext.Provider 
                value={{
                    ...this.state,
                    handleEventsChange: this.handleEventsChange,
                    getOneEvent : this.getOneEvent,
                    clearSelectedEvent: this.clearSelectedEvent, 
                    getAllUpcomingIOCEvents : this.getAllUpcomingIOCEvents,
                    getAllUpcomingBreakthroughEvents : this.getAllUpcomingBreakthroughEvents,
                    getAllUpcomingWebinarEvents : this.getAllUpcomingWebinarEvents,
                    getChildrenEventsOfParentEvent : this.getChildrenEventsOfParentEvent,
                    clearCachedEvent: this.clearCachedEvent,
                    resyncEvents: this.resyncEvents,
                    getAllUpcomingGrowEvents: this.getAllUpcomingGrowEvents
                }}>
                { this.props.children }
            </EventsContext.Provider>
        )
    }
}

export default EventsProvider

export const withEvents = C => props => (
    <EventsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </EventsContext.Consumer>
)

