import { ExternalNav, HashNav, BaseNav, SubDropdownNav } from './NavLinkTypes'

const GlobalNavbarDropdown = ({ links, collapseDropdown, displayMobileNav }) => {
    return links.map((option, i) => {
        switch(option.navType){
            case 'base-nav':
                return (
                    <BaseNav 
                        key={i} 
                        name={option.linkName} 
                        destination={option.to} 
                        clickFn={collapseDropdown} 
                        classname='global-navbar-dropdown-item text-body' 
                        linkClassName='navbar-dropdown-link' 
                    />
                )
            case 'hash-nav':
                return (
                    <HashNav 
                        key={i} 
                        name={option.linkName} 
                        destination={option.to} 
                        clickFn={collapseDropdown} 
                        classname='global-navbar-dropdown-item text-body' 
                        linkClassName='navbar-dropdown-link' 
                    />
                )
            case 'external-nav':
                return  (
                    <ExternalNav 
                        key={i} 
                        name={option.linkName} 
                        destination={option.to} 
                        clickFn={collapseDropdown} 
                        classname='global-navbar-dropdown-item text-body' 
                        linkClassName='navbar-dropdown-link' 
                    />
                )
            case 'title-only':
                return (
                    <SubDropdownNav 
                        key={i} 
                        name={option.linkName} 
                        classname='global-navbar-dropdown-title text-subheader' 
                        sublinks={option.links} subClassname='global-navbar-dropdown-item text-body' 
                        clickFn={collapseDropdown} 
                        linkClassName='navbar-dropdown-link' 
                        displayMobileNav={displayMobileNav} 
                    />
                )
            default: 
                return (
                    <BaseNav 
                        key={i} 
                        name={option.linkName} 
                        destination={option.to} 
                        clickFn={collapseDropdown} 
                        classname='global-navbar-dropdown-item text-body' 
                        linkClassName='navbar-dropdown-link' 
                    />
                )
        }
    })
}

export default GlobalNavbarDropdown
