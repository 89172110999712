import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCurrentWidth } from '../../../shared/CustomHooks/useCurrentWidth'
import ResourceFilter from './ResourceFilter'
import ResourceSearch from './ResourceSearch'


const ResourceFilterBar = ({ bloks }) => {
    const currentWindowWidth = useCurrentWidth()
    const displayMobile = currentWindowWidth < 769
    const [showingFilters, setShowingFilters] = useState(!displayMobile)

    const closeMobileFilter = () => {
        window.scrollTo(0, 0)
        setShowingFilters(false)
    }

    // close the mobile filters when a link is clicked
    useEffect(() => {
        if(displayMobile){
            setShowingFilters(false)
        }
    }, [window.location.pathname])


    return (
        <aside className='coachingLibrary-filterbar'>
            {!showingFilters ? 
                <button onClick={() => setShowingFilters(true)} className='coachingLibrary-filterbar-hamburger'>
                    <FontAwesomeIcon icon="bars" />
                </button>
            :
                <ResourceFilter closeMobileFilter={closeMobileFilter} displayMobile={displayMobile} />
            }
            <ResourceSearch displayMobile={displayMobile} bloks={bloks} />
        </aside>
    )
}

export default ResourceFilterBar