import { useEffect } from "react"

const ContactFormGeneral = () => {

    useEffect(() => {
        const script = document.createElement('script')
        script.src='//js.hsforms.net/forms/v2.js?pre=1'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "4127087",
                    formId: "655fd5ea-398d-4a35-aeb9-cda491c25682",
                    target: '#hubspotContactFormGeneral'
                })
            }
        })
    }, [])

    return (
        <div id="hubspotContactFormGeneral"></div>
    )
}

export default ContactFormGeneral
