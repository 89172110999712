import { useState } from 'react'
import { useCurrentWidth } from '../../../shared/CustomHooks/useCurrentWidth'

const FormatsTile = ({ format }) => {
    let currentWindowWidth = useCurrentWidth()
    const [displayContent, setDisplayContent] = useState(currentWindowWidth <= 900)

    return (
        <div className={`homepage-formats-tile homepage-formats-tile-${format.backgroundImage}`}  
            onMouseEnter={() => setDisplayContent(true)}
            onMouseLeave={() => setDisplayContent(false)}
        >
            <div className={displayContent ? 'homepage-formats-tile-content' : 'homepage-formats-tile-content-hidden'}>
                <strong className='subheader'>{format.title}</strong>
                <p>{format.description}</p>
            </div>
        </div>
    )
}

export default FormatsTile