import { useState, createContext, useContext } from 'react'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
import { authAxios } from '../shared/CustomAxios'


const baseURL = '/v1/api/a2b/employees'
const EmployeeContext = createContext()

export const useEmployees = () => useContext(EmployeeContext)


export const EmployeeProviderV2 = ({ children }) => {
    const [allEmployees, setAllEmployees] = useState([])
    const [usersDirectReports, setUsersDirectReports] = useState([])
    const [loggedInUserData, setLoggedInUserData] = useState({})
    const [loggedInEmployeeData, setLoggedInEmployeeData] = useState({})
    const [selectedEmployee, setSelectedEmployee] = useState({})
    const [employeeNotification, setEmployeeNotification] = useState(null)
    const [employeeError, setEmployeeError] = useState(null)
    // currently only used in pulses
    const [isShowingAddEmployeeModal, setIsShowingAddEmployeeModal] = useState(false)
    const [isShowingEditEmployeeModal, setIsShowingEditEmployeeModal] = useState(false)

    const getAllEmployees = () => {
        authAxios.get(`${baseURL}/`)
            .then(res => {
                setAllEmployees(res.data)
            })
            .catch(err => { throw err })
    }

    const getLoggedInEmployee = (id) => {
        authAxios.get(`${baseURL}/current/${id}`)
            .then(res => {
                setLoggedInUserData(res.data[0])
                setLoggedInEmployeeData(res.data[0].Employee)
            })
            .catch(err => {throw err})
    }

    const getUsersDirectReports = (userId, canViewAll) => {
        let query = canViewAll ? '' : `?reportsTo=${userId}`

        authAxios.get(`${baseURL}/direct-reports${query}`)
            .then(res => {
                setUsersDirectReports( res.data )
            })
            .catch(err => {throw err})
    }

    const employeeOnChange = (e) => {
        const { name, value, type, checked } = e.target
        let finalValue = type === 'checkbox' ? checked : sanitizeData(value)
        setSelectedEmployee({ ...selectedEmployee, [name]: finalValue })
    }

    const addEmployee = () => {
        authAxios.post(`${baseURL}/`, selectedEmployee )
            .then(res => {
                setEmployeeNotification(res.data)
                setSelectedEmployee({})
                setIsShowingAddEmployeeModal(false)
            })
            .catch(err => {
                setEmployeeError(err.response.data)
                setIsShowingAddEmployeeModal(false)
            });
    }

    const deleteEmployee = (id) => {
        authAxios.delete(`${baseURL}/${id}`)
            .then(() => {
                setEmployeeNotification('successfully removed from employee records' )
            })
            .catch(err => setEmployeeError( err ))
    }

    const editEmployee = () => {
        authAxios.put(`${baseURL}/${selectedEmployee.userId}`, selectedEmployee )
            .then(() => {
                setEmployeeNotification( 'Employee has been successfully updated.' )
            })
            .catch(err => setEmployeeError( err ))
    }

    const toggleAddEmployeeModal = () => setIsShowingAddEmployeeModal(!isShowingAddEmployeeModal)

    const toggleEditEmployeeModal = (user, employeeData, userId) => {
        if(isShowingEditEmployeeModal) {
            setIsShowingEditEmployeeModal(false) 
            setSelectedEmployee({})
        }else {
            setIsShowingEditEmployeeModal(true)
            setSelectedEmployee({...user, ...employeeData, userId}) 
        }
    }

    const clearEmployees = () => setAllEmployees([])

    return (
        <EmployeeContext.Provider 
            value={{
                //state
                loggedInUserData, 
                setLoggedInUserData,
                loggedInEmployeeData,
                setLoggedInEmployeeData,
                usersDirectReports,
                setUsersDirectReports,
                allEmployees,
                setAllEmployees,
                employeeNotification,
                setEmployeeNotification,
                employeeError,
                setEmployeeError,
                selectedEmployee,
                setSelectedEmployee,
                isShowingAddEmployeeModal,
                setIsShowingAddEmployeeModal,
                isShowingEditEmployeeModal,
                setIsShowingEditEmployeeModal,
                //functions
                getAllEmployees, 
                getLoggedInEmployee, 
                getUsersDirectReports, 
                employeeOnChange, 
                addEmployee, 
                deleteEmployee, 
                editEmployee, 
                toggleAddEmployeeModal, 
                toggleEditEmployeeModal, 
                clearEmployees, 
            }}>
            { children }
        </EmployeeContext.Provider>
    )
}