import { useEffect } from "react"

const ContactFormServiceSupport = () => {

    useEffect(() => {
        const script = document.createElement('script')
        script.src='//js.hsforms.net/forms/v2.js?pre=1'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: "na1",
                    portalId: "4127087",
                    formId: "3d173009-de8b-48a2-89c3-9047744aa986",
                    target: '#hubspotContactFormServiceSupport'
                })
            }
        })
    }, [])

    return (
        <div id="hubspotContactFormServiceSupport"></div>
    )
}

export default ContactFormServiceSupport
