import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExternalNav, HashNav, BaseNav, DropdownNav } from './NavLinkTypes'
import DropdownAnimate from '../../shared/DropdownAnimate'

const GlobalNavbar = ({ links, displayAdminModules, baseAccessRights, headerMobileDropdownRef, displayMobileNav, expanded, toggleNavbar, snapshotsEnabled, user, contactExists }) => {

    // used when displaying link for only a certain community,
    // check to make sure the baseAccessRights for the user include one of the communities
    function findCommonElements(arr1, arr2) {
        // make sure their company allows snapshots
        if(arr1.includes('Coaching') && !snapshotsEnabled){
            return false
        } else {
            return arr1.some(item => arr2.includes(item))
        }
    }

    const navMap = links.map((link, i) => {
        switch(link.navType){
            case 'base-nav':
                return (
                    <BaseNav 
                        key={i} 
                        name={link.linkName} 
                        destination={link.to} 
                        classname='global-navbar-list-item' 
                        linkClassName='navbar-link' 
                        isMobileOnly={link.mobileOnly}
                    />
                )
            case 'hash-nav':
                return (
                    <HashNav 
                        key={i} 
                        name={link.linkName} 
                        destination={link.to} 
                        classname='global-navbar-list-item' 
                        linkClassName='navbar-link' 
                        displayMobileNav={displayMobileNav} 
                    />
                )
            case 'external-nav':
                return (
                    <ExternalNav 
                        key={i} 
                        name={link.linkName} 
                        destination={link.to} 
                        classname='global-navbar-list-item' 
                        linkClassName='navbar-link' 
                    />
                )
            case 'dropdown-nav':
                return (
                    <DropdownNav 
                        key={i} 
                        name={link.linkName} 
                        destination={link.to} 
                        classname='global-navbar-list-item' 
                        linkClassName='navbar-link' 
                        dropdownClassName='global-navbar-dropdown'
                        dropdownLinks={link.dropdown} 
                        displayMobileNav={displayMobileNav} 
                    />
                )
            case 'admin-nav': 
                if(displayAdminModules){
                    return (
                        <BaseNav 
                            key={i} 
                            name={link.linkName} 
                            destination={link.to} 
                            classname='global-navbar-list-item' 
                            linkClassName='navbar-link' 
                            isMobileOnly={link.mobileOnly}
                        />
                    )
                } else {
                    break;
                }
            case 'community-specific':
                if(findCommonElements(baseAccessRights, link.communities)){
                    // set default routes if user only has access to one
                    let destination;
                    if(!user.hasCoaching && user.hasGrowCoaching){
                        destination=`${link.to}/grow-coaching`
                    } else if(user.hasCoaching && !user.hasGrowCoaching){
                        destination=`${link.to}/insideout-coaching`
                    } else {
                        destination = link.to
                    }

                    return (
                        <BaseNav 
                            key={i} 
                            name={link.linkName} 
                            destination={destination} 
                            classname='global-navbar-list-item' 
                            linkClassName='navbar-link' 
                        />
                    )
                } else {
                    break;
                }
            case 'hash-nav-button':
                if(contactExists){
                    return (
                        <HashNav 
                            key={i} 
                            name={link.linkName} 
                            destination={link.hash} 
                            classname='global-navbar-list-item' 
                            linkClassName='salmon-button' 
                            displayMobileNav={displayMobileNav} 
                        />
                    )
                } else {
                    return (
                        <BaseNav 
                            key={i} 
                            name={link.linkName} 
                            destination={link.to} 
                            classname='global-navbar-list-item' 
                            linkClassName='salmon-button' 
                            isMobileOnly={link.mobileOnly}
                        />
                    )
                }
            default:
                return (
                    <BaseNav 
                        key={i} 
                        name={link.linkName} 
                        destination={link.to} 
                        classname='global-navbar-list-item' 
                        linkClassName='navbar-link' 
                    />
                )
        }
    })


    return (
        <nav className='global-navbar navigation-links-header' ref={headerMobileDropdownRef}>
            {displayMobileNav ?
                <>
                    <FontAwesomeIcon icon={expanded ? "times" : "bars"} className='global-header-hamburger' onClick={toggleNavbar} /> 
                    <DropdownAnimate show={expanded}> 
                        <ul className={'global-navbar-list text-title'}>
                            {navMap}
                        </ul>
                    </DropdownAnimate> 
                </>
                :
                <ul className={'global-navbar-list text-title'} style={{visibility:     !displayMobileNav ? 'visible' : 'hidden'}}>
                    {navMap}
                </ul>
            }

        </nav>
    )
}

export default GlobalNavbar
