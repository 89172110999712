import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player'
import ImageHero from '../Shared/ImageHero'
import ClientsCarousel from '../Shared/ClientsCarousel'
import SolutionsChart from './SolutionsChart'
import SuccessStoriesSection from './SuccessStoriesSection'
import ContactBanner from '../Contact/ContactBanner'
import BlockquoteSectionIcons from '../Shared/BlockquoteSectionIcons'
import FormatsSection from './FormatsSection'
import HomepageReshapingImage from '../../../Assets/images/Public-Website/design/homepage-reshaping.svg'

const Homepage = () => {
    const [playing, setPlaying] = useState(false)

    let heroData = {
        layout: 'homepage',
        title: <>Powerful, <br/>Simple Coaching</>,
        caption:"GROW® Coaching helps unlock people's potential, improve focus, and achieve more, creating measurable value to your organization.",
        link:'/solutions/grow-coaching' ,
        linkName:'Explore GROW®'
    }


    return (
        <main className='homepage'>
            <Helmet>
                <title>GROW Model Coaching & Mentoring</title>
                <meta name="GROW Model Coaching & Mentoring" />
            </Helmet>
            <ImageHero data={heroData} />
            <section className='homepage-reshaping'>
                <header className='section-header'>
                    <h2>Reshaping How You Think About Coaching</h2>
                </header>
                <p className='text-body-lg'>Our GROW® Coaching programs are rooted in scientifically proven methodologies built upon simple fundamentals. We know how to unlock potential, improve individual and team performance, yielding measurable results.</p>
                <img src={HomepageReshapingImage} alt="Reshaping How You Think About Coaching Visualized" />
            </section>
            <BlockquoteSectionIcons quote="Coaching isn't just something we do, it's the WAY we do things." />
            <FormatsSection />
            <ClientsCarousel heading='Proud to Work With Companies Like' />
            <section className='solutions-landing-video'>
                <div className='solutions-landing-video-thumbnail'>
                    <ReactPlayer 
                        className='solutions-landing-video-thumbnail'
                        playing={playing}
                        onClick={() => setPlaying(!playing)}
                        url={'https://insideoutdev.wistia.com/medias/f8gvf3jund'}
                    />
                </div>
                <article className='col section-public-content'>
                    <h2>On a mission to improve team performance</h2>
                    <strong className='text-subheader'>Meet our founder, GROW<sup>®</sup> Model co-creator, and pioneer of the modern-day coaching movement</strong>
                    <p className='text-body' style={{maxWidth: 'unset'}}>InsideOut coaches people to coach instead of just training them to coach, an approach based on principles developed by founder and New York Times Best Selling Author, Alan Fine, during his four decades of coaching professional athletes, entertainers and senior executives.</p>
                    <button className='salmon-button' onClick={() => setPlaying(!playing)}>
                    {!playing ? 
                        <span>
                            Watch Now <FontAwesomeIcon icon={["far", "play-circle"]} />
                        </span>
                        :
                        <span>
                            Pause <FontAwesomeIcon icon={["far", "pause-circle"]} />
                        </span>
                    }
                    </button>
                </article>
            </section>
            <SolutionsChart />
            <SuccessStoriesSection />
            <ContactBanner title='Ready to Get Started?'/>
        </main>
    )
}

export default Homepage
