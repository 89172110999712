import { useState } from 'react'
import SemiCircleHeader from '../Shared/SemiCircleHeader'
import ContactFormGeneral from './ContactFormGeneral'
import ContactFormServiceSupport from './ContactFormServiceSupport'
import OurOffice from '../Shared/OurOffice'
// import RDMap from '../Shared/RDMap/RDMap'

const ContactPage = () => {
    const [ selectedForm, setSelectedForm ] = useState('general')

    return (
        <main className='sprout-typography'>
            <SemiCircleHeader headerText='Get In Touch With Us' />

            <article className='contact-us-form-section'>
                <h2>How can we help?</h2>
                <aside className='contact-us-form-section-toggle'>
                    <button 
                        className={`contact-us-form-section-toggle-button ${selectedForm === 'general' && 'active'}`}
                        onClick={() => setSelectedForm('general')}
                    > 
                        General Inquiries 
                    </button>
                    <button 
                        className={`contact-us-form-section-toggle-button ${selectedForm === 'support' && 'active'}`}
                        onClick={() => setSelectedForm('support')}
                    >
                        Service / Support
                    </button>
                </aside>

                {selectedForm === 'general'  && 
                    <>
                        <h3>Have Someone Contact Me</h3>
                        <ContactFormGeneral />
                    </>
                }

                {selectedForm === 'support'  && 
                    <>
                        <h3>Service & Support</h3>
                        <ContactFormServiceSupport /> 
                    </>
                }
            </article>

            <OurOffice />
            {/* <RDMap /> */}
        </main>
    )
}

export default ContactPage
