import { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PublicRoute } from './PublicRoute'
import NotFound404 from '../../GlobalComponents/NotFound404/NotFound404'
import { ExternalRedirect } from '../../GlobalComponents/ExternalRedirects/ExternalRedirect'
import { appDomain, registerDomain } from '../../config/Config'
import Loader from '../../GlobalComponents/Loader/Loader'

const SubdomainRedirects = () => {
    const renderLoader = () => <Loader />
	return (
        <Suspense fallback={renderLoader()}>
            <Switch>
			<Route exact path='/a2b' component={() => ExternalRedirect(`${appDomain}/a2b`)} />
			<Route path='/a2b/resources' component={() => ExternalRedirect(`${appDomain}/a2b/resources`)} />
			<Route path='/a2b/pulse' component={() => ExternalRedirect(`${appDomain}/a2b/pulse`)} />
			<Route path='/a2b/analytics' component={() => ExternalRedirect(`${appDomain}/a2b/analytics`)} />
			<Route exact path='/a2b/coordinator-zone' component={() => ExternalRedirect(`${appDomain}/a2b/coordinator-zone`)} />
			<Route exact path='/a2b/coordinator-zone/file-access' component={() => ExternalRedirect(`${appDomain}/a2b/coordinator-zone/file-access`)} />
			<Route exact path='/a2b/coordinator-zone/registration-links' component={() => ExternalRedirect(`${appDomain}/a2b/coordinator-zone/registration-links`)} />
            <Route path='/dashboard' component={() => ExternalRedirect(`${appDomain}/dashboard`)} />
			<Route exact path='/digital-foundations/btdf' component={() => ExternalRedirect(`${appDomain}/digital-foundations/btdf`)} />
			<Route path='/digital-foundations/btdf/bursts/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/btdf`)} />
			<Route path='/digital-foundations/btdf/tools' component={() => ExternalRedirect(`${appDomain}/digital-foundations/btdf/tools`)} />
			<Route exact path='/digital-foundations/iocdf' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf`)} />
			<Route path='/digital-foundations/iocdf/bursts/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf`)} />
			<Route path='/digital-foundations/iocdf/tools' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/tools`)} />
			<Route path='/digital-foundations/iocdf/admin/participants' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/participants`)} />
			<Route exact path='/digital-foundations/iocdf/admin/invite-participants' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/invite-participants`)} />
			<Route exact path='/digital-foundations/iocdf/admin/sessions' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/sessions`)} />
			<Route exact path='/digital-foundations/iocdf/admin/sessions/create-session' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/sessions/create-session`)} />
			<Route exact path='/digital-foundations/iocdf/admin/sessions/edit-session/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/sessions`)} />
			<Route exact path='/digital-foundations/iocdf/admin/sessions/invite-participants/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/sessions`)} />
			<Route path='/digital-foundations/iocdf/admin/sessions/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/sessions`)} />
			<Route path='/digital-foundations/iocdf/admin/emails' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocdf/admin/emails`)} />
			<Route exact path='/digital-foundations/iocedu' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu`)} />
			<Route path='/digital-foundations/iocedu/bursts/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/bursts`)} />
			<Route path='/digital-foundations/iocedu/tools' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/tools`)} />
			<Route path='/digital-foundations/iocedu/admin/participants' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/participants`)} />
			<Route exact path='/digital-foundations/iocedu/admin/sessions' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/sessions`)} />
			<Route exact path='/digital-foundations/iocedu/admin/sessions/create-session' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/sessions/create-session`)} />
			<Route exact path='/digital-foundations/iocedu/admin/sessions/edit-session/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/sessions`)} />
			<Route exact path='/digital-foundations/iocedu/admin/sessions/invite-participants/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/sessions/invite-participants/`)} />
			<Route path='/digital-foundations/iocedu/admin/sessions/:id' component={() => ExternalRedirect(`${appDomain}/digital-foundations/iocedu/admin/sessions/`)} />
            <Route path='/features' component={() => ExternalRedirect(`${appDomain}/features`)} />
            <Route path='/forgot-password' component={() => ExternalRedirect(`${appDomain}/forgot-password`)} />
			<Route exact path='/grow-coaching/prework' component={() => ExternalRedirect(`${appDomain}/grow-coaching/prework`)} />
			<Route path='/grow-coaching/snapshots/report' component={() => ExternalRedirect(`${appDomain}/grow-coaching/snapshots/report`)} />
			<Route exact path='/lms/snapshots' component={() => ExternalRedirect(`${appDomain}/lms/snapshots`)} />
			<Route path='/lms/snapshots/report/:containerId' component={() => ExternalRedirect(`${appDomain}/lms/snapshots`)} />
			<Route path='/participant-portal/snapshots/grow-coaching/report' component={() => ExternalRedirect(`${appDomain}/participant-portal/snapshots/grow-coaching`)} />
			<Route path='/participant-portal/snapshots/insideout-coaching/report' component={() => ExternalRedirect(`${appDomain}/participant-portal/snapshots/insideout-coaching`)} />
			<Route path='/participant-portal/participants/:view' component={() => ExternalRedirect(`${appDomain}/participant-portal`)} />
			<Route path='/participant-portal/grow-coaching/prework' component={() => ExternalRedirect(`${appDomain}/participant-portal/grow-coaching/prework`)} />
			<Route path='/participant-portal/insideout-coaching/prework' component={() => ExternalRedirect(`${appDomain}/participant-portal/insideout-coaching/prework`)} />
			<Route path='/participant-portal/help' component={() => ExternalRedirect(`${appDomain}/participant-portal/help`)} />
			<Route path='/participant-portal/snapshots/history' component={() => ExternalRedirect(`${appDomain}/participant-portal/snapshots/history`)} />
			<Route path='/participant-portal/snapshots/:product' component={() => ExternalRedirect(`${appDomain}/participant-portal`)} />
			<Route path='/participant-portal/library' component={() => ExternalRedirect(`${appDomain}/participant-portal/library`)} />
			<Route path='/participant-portal/preferences' component={() => ExternalRedirect(`${appDomain}/participant-portal/preferences`)} />
			<Route path='/participant-portal/snapshots' component={() => ExternalRedirect(`${appDomain}/participant-portal/snapshots`)} />
			<Route path='/participant-portal/tags' component={() => ExternalRedirect(`${appDomain}/participant-portal/tags`)} />
			<Route exact path='/participant-portal' component={() => ExternalRedirect(`${appDomain}/participant-portal`)} />
            <Route path='/password-reset/:id' component={() => ExternalRedirect(`${appDomain}/password-reset/`)} />
            <Route path='/portal/login' component={() => ExternalRedirect(`${appDomain}/portal/login`)} />
            <Route path='/portal/register/' component={() => ExternalRedirect(`${appDomain}/portal/register/`)} />
            <Route path='/portal/register/:id' component={() => ExternalRedirect(`${appDomain}/portal/register/`)} />
            <Route path='/register/:id' component={() => ExternalRedirect(`${registerDomain}/register/`)} />
            <Route path='/snapshots/coaching-snapshot/v1/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v1/`)} />
            <Route path='/snapshots/coaching-snapshot/v2/feedback/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v2/feedback/`)} />
            <Route path='/snapshots/coaching-snapshot/v2/' component={() => ExternalRedirect(`${appDomain}/snapshots/coaching-snapshot/v2/`)} />
            <PublicRoute path='*' component={NotFound404} />
            </Switch>
        </Suspense>
	)
}

export default SubdomainRedirects
