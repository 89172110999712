import FormatsTile from './FormatsTile'
import { FormatData } from './HomepageData'

const FormatsSection = () => {
    const learningFormats = FormatData.map(format => (
        <FormatsTile key={format.title} format={format} />
    ))

    return (
        <article>
            <header className='section-header'>
                <h2>Offered in a Variety of Learning Formats</h2>
            </header>
            <section className='homepage-formats'>
                {learningFormats}
            </section>
        </article>
    )
}

export default FormatsSection