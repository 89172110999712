
const SemiCircleHeader = ({ headerText, caption }) => {
    return (
        <header className='header-semicircle'>
            <h1>{headerText}</h1>
            {caption && <p className='text-body'>{caption}</p>}
        </header>
    )
}

export default SemiCircleHeader