import React, { Component } from 'react'
import axios from 'axios'

const TutorialsContext = React.createContext()

class TutorialsProvider extends Component {
    constructor(){
        super()
        this.state = {
            step: 1,
            currentUserData: null,
            user: {}
        }
    }

    toggleStep = (value) => {
        this.setState({ step: this.state.step + value })
    }

    sendUserDataToTutorialsProvider = (user) => {
        this.setState({ user, step: 1 })
    }

    userCompletedPrepEssentials = (history) => {
        localStorage.removeItem("iodToken")
        let body = { isNewIOCUser: false }

        axios
            .put(`/v1/auth/user/${this.state.user.id}`, body)
            .then(() => {
                this.generateNewUserToken(history)
            })
            .catch((err) => {
                throw err;
        })
    }

    generateNewUserToken = (history) => {
        axios.get(`/v1/auth/generate-new-token/${this.state.user.id}`)
        .then(res => {
            const { iodToken } = res.data
            localStorage.setItem("iodToken", iodToken)
            history.push('/coaching')
        })
        .catch (err => {
            return err
        })
    }

    cleanUp = () => {
        this.setState({ currentUserData: null })
    }

    render(){
        return (
            <TutorialsContext.Provider 
                value={{
                    ...this.state,
                    getUser: this.getUser,
                    toggleStep: this.toggleStep,
                    sendUserDataToTutorialsProvider: this.sendUserDataToTutorialsProvider,
                    userCompletedPrepEssentials: this.userCompletedPrepEssentials,
                    cleanUp: this.cleanUp
                }}>
                { this.props.children }
            </TutorialsContext.Provider>
        )
    }
}

export default TutorialsProvider

export const withTutorials = C => props => (
    <TutorialsContext.Consumer>
        {value => <C {...props} {...value}/>}
    </TutorialsContext.Consumer>
)