import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import { IOCT3PriceString, T3PriceString, IOCPriceString, breakthroughsPriceString } from '../config/Config'
import sanitizeData from '../shared/HelperFunctions/SanitizeData'
// import breakthroughsRegistrationEmail from '../shared/EmailTemplates/breakthroughsRegistrationEmail'
// import sendNodeMailer from '../shared/HelperFunctions/sendNodeMailer'
// import { emailSenderAddress } from '../config/Config'


const IOCT3Price = parseInt(IOCT3PriceString)
const T3Price = parseInt(T3PriceString)
const IOCPrice = parseInt(IOCPriceString)
const breakthroughsPrice = parseInt(breakthroughsPriceString)
const eventBaseURL = '/v1/iod/events';
const authorizatonBaseURL = '/v1/authorizations'
const promoBaseURL = '/v1/iod/promocode';
const campaignMemberBaseURL = '/v1/ioc/campaign-members'
const RegistrationContext = React.createContext()

const initialState = {
    registrationSelectedEvent: {},
    parentOfSelectedEvent: {},
    selectedEventId: '',
    selectedEventType: '',
    defaultEventPrice: 0,
    pricePerParticipant: 0,
    totalPrice: 0,
    stripeTotalPrice: 100,
    registrationEventDetails: '',
    confirmHasPreviouslyAttended: false,
    additionalParticipants: false,
    currentStep: 1,
    promoCode: '',
    promoCodeDataObject: {},
    isPromocodeValid: null,
    alreadyUsedPromoCode: false,
    promoCodeError: null,
    promoCodeNotification: '',
    isShowingEditUserModal: false,
    isShowingEditBillingContactModal: false,
    isShowingEditBillingAddressModal: false,
    isShowingDeleteParticipantModal: false,
    useParticipantInfo: false,
    billingFirstName: '',
    billingLastName: '',
    billingEmail: '',
    billingTitle: '',
    billingCompany: '',
    billingPhone: '',
    billingStreetOne: '',
    billingStreetTwo: '',
    billingCity: '',
    billingState: '',
    billingCountry: 'United States',
    billingZipCode: '',
    billingPaymentMethod: "Credit Card",
    billingCreditCardNumber: 0,
    billingSecurityCode: 0,
    billingExpirationDate: '',            
    addParticipant: {
        firstName : '',
        lastName : '',
        email : '',
        title : '',
        company : '',
        phone : '',
    },
    editingParticipantIndex: null,
    participants: [],
    orderConfirmationNumber: '',
    orderDidProcess: null,
    displayLoadingIcon: false
}

class RegistrationProvider extends Component {
    constructor(){
        super()
        this.state = initialState
    }


    noPayment = () => {
        this.setState({billingPaymentMethod: "Invoice"})
    }


    toggleUseParticipantInfo = () => {
        const participant = this.state.participants[0]
        this.setState({ useParticipantInfo: !this.state.useParticipantInfo,
            billingFirstName: participant.firstName,
            billingLastName: participant.lastName,
            billingEmail: participant.email,
            billingTitle: participant.title,
            billingCompany: participant.company,
            billingPhone: participant.phone
        })
    }


    cancelUseParticipantInfo = () => {
        this.setState({ useParticipantInfo: false })
    }


    handleChangeRegistration  = (e) => {
        let { name, value, type } = e.target
        value = sanitizeData(value)
        if(type === 'checkbox'){
            this.setState({ [name]: !this.state[name]})
        } else {
            this.setState({ [name]: value })
        }
    }


    handleProgramSelection = (e) => {
        const { id } = e.target
        this.setState({ selectedEventId: id })
        axios.get(`${eventBaseURL}/${id}`)
        .then(res => {
            const parentId = res.data[0].ParentId
            const campaignClass = res.data[0].Campaign_Class__c
            this.setState({registrationSelectedEvent: res.data[0]})

            if(
                campaignClass === 'IOC Registration' 
                || campaignClass === 'GROW Coaching Registration'
            ){
                this.setState({  defaultEventPrice: IOCPrice, pricePerParticipant: IOCPrice, selectedEventType: 'IOC', registrationEventDetails: 'Workshop', totalPrice: IOCPrice*this.state.participants.length })
                this.getParentEvent(parentId)
            } else if(
                campaignClass === 'IOCT3 Registration' 
                || campaignClass === 'GROW Coaching T3 Registration'
            ){
                this.setState({  defaultEventPrice: T3Price, pricePerParticipant: T3Price, selectedEventType: 'T3', registrationEventDetails: 'Certification', totalPrice: T3Price*this.state.participants.length })
                this.getParentEvent(parentId)
            } else if(
                campaignClass === 'IOC and T3 Registration' 
                || campaignClass === 'GROW Coaching and T3 Registration'
            ){
                this.setState({  defaultEventPrice: IOCT3Price, pricePerParticipant: IOCT3Price, selectedEventType: 'IOC&T3', registrationEventDetails: 'Workshop + Certification', totalPrice: IOCT3Price*this.state.participants.length })
                this.getParentEvent(parentId)
            } else if(
                campaignClass === 'Breakthroughs'
            ){
                let selectedChildBtEvent = this.state.registrationSelectedEvent
                selectedChildBtEvent.Campaign_Class__c = 'Breakthroughs'
                this.setState({  defaultEventPrice: breakthroughsPrice, pricePerParticipant: breakthroughsPrice, selectedEventType: 'breakthroughs', registrationEventDetails: 'InsideOut Breakthroughs Facilitator Certification', registrationSelectedEvent: selectedChildBtEvent })
                this.getParentEvent(parentId)
            }
        })
        .catch( err => err )
    }

    
    getParentEvent = (parentId) => {
        axios.get(`${eventBaseURL}/${parentId}`)
        .then(res => {
            this.setState({ parentOfSelectedEvent: res.data[0]});
        })
        .catch( err => err )
    }


    handleChangeParticipant = (e) => {
        let { name, value, type } = e.target
        value = sanitizeData(value)
        
        type === 'checkbox' ?
        this.setState({ 
            addParticipant: {
                ...this.state.addParticipant,
                [name]: !this.state.addParticipant[name]
            }
        })
        :
        this.setState({
            addParticipant: {
                ...this.state.addParticipant,
                [name] : value
            }
        })
    }


    handleAddParticipant = (e) => {
        e.preventDefault()
        if(this.state.addParticipant.firstName.length > 0 ){
            this.setState({
                participants: [...this.state.participants, this.state.addParticipant]}, () => this.setState({ 
                    totalPrice: this.state.pricePerParticipant*this.state.participants.length, 
                    stripeTotalPrice: (this.state.pricePerParticipant*this.state.participants.length)*100 }))
        }
        this.setState({ 
            addParticipant: {
                firstName : '',
                lastName : '',
                email : '',
                title : '',
                company : '',
                phone : ''
            }
        });
    }


    toggleEditParticipantModal = (index) => {
        index >= 0 ?
        this.setState({ isShowingEditUserModal: !this.state.isShowingEditUserModal, editingParticipantIndex: index, 
            addParticipant: {
                        firstName : this.state.participants[index].firstName,
                        lastName : this.state.participants[index].lastName,
                        email : this.state.participants[index].email,
                        company : this.state.participants[index].company,
                        phone : this.state.participants[index].phone,
                        title : this.state.participants[index].title},
                    })
        :
        this.setState({ isShowingEditUserModal: !this.state.isShowingEditUserModal, editingParticipantIndex: null })
    }


    handleEditParticipant = (e) => {
        const { name, value } = e.target;
        this.setState({
            addParticipant: {
                ...this.state.addParticipant,
                [name] : value
            }
        })
    }


    handleUpdateEditedParticipant = () => {
        const index = this.state.editingParticipantIndex
        const participants = this.state.participants
        const editedParticipant = this.state.addParticipant
        participants.splice(index , 1, editedParticipant)
        this.toggleEditParticipantModal()
    }


    toggleEditBillingContactModal = () => {
        this.setState({ isShowingEditBillingContactModal: !this.state.isShowingEditBillingContactModal })
    }


    toggleEditBillingAddressModal = () => {
        this.setState({ isShowingEditBillingAddressModal: !this.state.isShowingEditBillingAddressModal })
    }


    handleDeleteParticipant = (index) => {
            let participants = this.state.participants
            participants.splice(index, 1)
            this.setState({participants: participants}, () => this.setState({ 
                totalPrice: this.state.pricePerParticipant*this.state.participants.length, 
                stripeTotalPrice: (this.state.pricePerParticipant*this.state.participants.length)*100 }))
        }
    

    toggleDeleteParticipantModal = (index) => {
        index >= 0 ?
        this.setState({ 
            isShowingDeleteParticipantModal: !this.state.isShowingDeleteParticipantModal, 
            editingParticipantIndex: index, 
            addParticipant: {
                firstName : this.state.participants[index].firstName,
                lastName : this.state.participants[index].lastName,
            }
        })
        :
        this.setState({ isShowingDeleteParticipantModal: !this.state.isShowingDeleteParticipantModal, editingParticipantIndex: null })
    }


    handleNextStep = () => {
        let currentStepState = this.state.currentStep;
        this.setState({ currentStep: currentStepState += 1 })
    }


    handleBackStep = () => {
        let currentStepState = this.state.currentStep;
        this.setState({ currentStep: currentStepState -= 1, orderDidProcess: null })
    }


    checkPromoCode = (e) => {
        e.preventDefault()
        let campaignClass = this.state.registrationSelectedEvent.Campaign_Class__c

        axios.get(`${promoBaseURL}/${this.state.promoCode}`)
        .then(res => {
            let promoCodeEventValidity = res.data[0].validFor

            if(promoCodeEventValidity === campaignClass){
                this.setState({isPromocodeValid: true, promoCodeDataObject: res.data[0] })
                this.applyPromoCode(res.data[0])
            } else {
                this.setState({isPromocodeValid: false, promoCodeNotification: 'This promo code is not valid for the selected event' })
            }
        })
        .catch(() => {
            this.setState({
                isPromocodeValid: false, 
                promoCodeNotification: 'Something broke or that promo code was invalid.  Please try again.' 
            })
        })
    }

    applyPromoCode = (codeToApply) => {
        //  Check if promo code has already been applied to order
        if(!this.state.alreadyUsedPromoCode  ){
            //  Check if there are valid redemptions left with that promo code or if there are limits on redemptions
            if( (codeToApply.timesUsed < codeToApply.maxUsage) || codeToApply.maxUsage === null ){
                // Process promo code
                codeToApply.timesUsed += 1
                axios.put(`${promoBaseURL}/${codeToApply.id}`, codeToApply)
                .then(res => {
                    if(res.status === 200){
                        let newPrice = this.state.pricePerParticipant+codeToApply.value 
                        let updatedPrice;
                        newPrice >= 0 ? updatedPrice = newPrice : updatedPrice = 0
                        this.setState({
                            pricePerParticipant: updatedPrice, alreadyUsedPromoCode : true, 
                            totalPrice: updatedPrice*this.state.participants.length,
                            stripeTotalPrice: (updatedPrice*this.state.participants.length)*100,
                            promoCodeError: ''
                        })
                    }
                })
                .catch(() => {
                    //  The promo code was valid, but did not process.
                    this.setState({ promoCodeError: 'Promo code did not process.  Please try again.' })
                })
            }else{
                this.setState({ promoCodeError: 'This promo code is expired or has already been redeemed.' })
            }
        } else {
            this.setState({ promoCodeError: 'You may only apply one promo code per order.' })
        }
        
    }

    removePromoCode = () => {
        let promoCodeObject = this.state.promoCodeDataObject
        promoCodeObject.timesUsed -= 1
        axios.put(`${promoBaseURL}/${this.state.promoCodeDataObject.id}`, promoCodeObject)
        .then(res => {
            if(res.status === 200){
                let newPrice;
                if(this.state.selectedEventType === 'IOC&T3'){
                    newPrice = IOCT3Price
                }else if(this.state.selectedEventType === 'T3'){
                    newPrice = T3Price
                }else if(this.state.selectedEventType === 'IOC'){
                    newPrice = IOCPrice
                }else if(this.state.selectedEventType === 'breakthroughs'){
                    newPrice = breakthroughsPrice
                }
                this.setState({
                    pricePerParticipant: newPrice,
                    totalPrice: newPrice*this.state.participants.length,
                    stripeTotalPrice: (newPrice*this.state.participants.length)*100,
                    promoCode: '',
                    isPromocodeValid: null,
                    alreadyUsedPromoCode: false,
                    promoCodeError: null,
                })
            }
        })
        .catch(() => {
            //  The promo code was valid, but did not process removal.
            this.setState({ promoCodeError: 'Promo code did not remove.  Please try again.' })
        })
    }


    handleSelectCountry = (val) => this.setState({ billingCountry: val, billingState: '' })
    clearRegistrationProviderState = () => this.setState(initialState)
    toggleLoader = () => this.setState({ displayLoadingIcon: !this.state.displayLoadingIcon })
    windowReset = () =>  window.scrollTo(0, 320)
    handleSelectState = (val) => this.setState({ billingState: val })


    handleSubmitRegistration = (uniqueOrderNumber) => {
        this.setState({orderConfirmationNumber: uniqueOrderNumber }, () => this.setCustomRegistrationData() )
    }


    setCustomRegistrationData = () => {
        let eventCityState;
        let eventState;
        let eventCountry;
        let eventZipCode;

        if(!this.state.parentOfSelectedEvent.State__c){
            eventState = ' '
        }else {
            eventState = this.state.parentOfSelectedEvent.State__c
        }

        if(!this.state.parentOfSelectedEvent.Zip_Code__c){
            eventZipCode = ' '
        } else {
            eventZipCode = this.state.parentOfSelectedEvent.Zip_Code__c
        }

        if(!this.state.parentOfSelectedEvent.Event_Country__c || this.state.parentOfSelectedEvent.Event_Country__c === 'United States'){
            eventCountry = ' '
        } else {
            eventCountry = this.state.parentOfSelectedEvent.Event_Country__c
        }

        let eventVenue; 
        let eventAddress;

        this.state.parentOfSelectedEvent.Venue__c === null ? eventVenue = 'TBD' : eventVenue = this.state.parentOfSelectedEvent.Venue__c
        this.state.parentOfSelectedEvent.Street_Address__c === null ? eventAddress = 'TBD' : eventAddress = this.state.parentOfSelectedEvent.Street_Address__c
        
        if(this.state.registrationSelectedEvent.Campaign_Class__c === 'Breakthroughs'){
            eventCityState = this.state.registrationSelectedEvent.City__c 
        } else {
            eventCityState = `${this.state.parentOfSelectedEvent.City__c}, ${eventState} ${eventCountry}${eventZipCode}`
        }

        let billingInfo = ''
        if(!this.state.billingStreetTwo){
            billingInfo =   `${this.state.billingCompany} 
                            ${this.state.billingFirstName} ${this.state.billingLastName}
                            ${this.state.billingEmail}
                            ${this.state.billingPhone}
                            ${this.state.billingStreetOne}
                            ${this.state.billingCity}, ${this.state.billingState}, ${this.state.billingZipCode}
                            ${this.state.billingCountry} `
        } else {
            billingInfo =   `${this.state.billingCompany} 
                            ${this.state.billingFirstName} ${this.state.billingLastName}
                            ${this.state.billingEmail}
                            ${this.state.billingPhone}
                            ${this.state.billingStreetOne}
                            ${this.state.billingStreetTwo}
                            ${this.state.billingCity}, ${this.state.billingState}, ${this.state.billingZipCode}
                            ${this.state.billingCountry} `
        }
        this.buildRegistrationData(eventCityState, eventVenue, eventAddress, billingInfo)
    }


    //  Use this when we eliminate hubspot and want to pass data directly to Zapier
    buildRegistrationData = ( eventCityState, eventVenue, eventAddress, billingInfo) => {
        let pendingPromises = []
        this.state.participants.forEach( (participant, index) => {
            let registrationData = {
                "email" : participant.email , 
                "firstname" : participant.firstName , 
                "lastname" : participant.lastName , 
                "company" : participant.company , 
                "phone" : participant.phone , 
                "jobtitle" : participant.title ,
                "address" :  this.state.billingStreetOne + this.state.billingStreetTwo ,
                "city" : this.state.billingCity ,
                "state" : this.state.billingState ,
                "country" : this.state.billingCountry, 
                "zip" : this.state.billingZipCode ,
                "salesforce_campaign_id" : this.state.selectedEventId , 
                "payment_type" : this.state.billingPaymentMethod , 
                "total_payment_amount" : this.state.totalPrice ,  // Payment value (in dollars)
                "price_per_participant" : this.state.pricePerParticipant ,
                "total_registered_participants" : this.state.participants.length , 
                "idempotency_key" : this.state.orderConfirmationNumber , 
                "zap_submission_iteration" : index+1 , // The current iteration of form to be submitted to zapier 
                "billing_info" :  billingInfo , 
                "billing_contact_email" : this.state.billingEmail,
                "classroom_link_1_day": this.state.registrationSelectedEvent.Classroom_Link_1_Day__c,
                "classroom_link_t3": this.state.registrationSelectedEvent.Classroom_Link_T3__c,
                "domain": participant.email.slice(participant.email.indexOf('@')+1),
            }
            let access = this.state.selectedEventType
            if(this.state.selectedEventType !== 'breakthroughs'){
                // currently all overflow events are for GROW Coaching, comment out the check and leave community = 'coaching' if this changes.
                if(this.state.parentOfSelectedEvent.Campaign_Class__c.includes("GROW")){
                    access = { grantGrowCoaching: true }
                } else {
                    access = { grantIoc: true }
                }
                registrationData["delivery_format"] = this.state.registrationSelectedEvent.Delivery_Format__c 
                registrationData["registration_event_details"] = this.state.registrationEventDetails 
                registrationData["event_venue"] = eventVenue  
                registrationData["event_address"] = eventAddress 
                registrationData["event_city_state_zip"] = eventCityState 
            } 
            pendingPromises.push(this.createAuthorizations(participant.email, participant.company, access))
            pendingPromises.push(this.createNewCampaignMember( participant.email, participant.firstName, participant.lastName ))
            pendingPromises.push(this.submitRegistration(registrationData))
        })
        Promise.all(pendingPromises)
    }

    createAuthorizations = async (email, company, access) => {
        let body = {
            email: email,
            company: company,
            access: access
        }

        try {
            let res = await axios.post(authorizatonBaseURL, body)
            return res
        } catch (err) {
            return err
        }
    }


    //  Sends all the registration data to Zapier via API calls to inject into Salesforce
    submitRegistration = async (registrationData) => {
        console.log(registrationData) // For Logrocket evaluation. Also captured in the server log via the route below.

        let routerEndpoint = '/v1/registrations/public-program'

        await axios.post( routerEndpoint , registrationData )
        .then(() => this.setState({orderDidProcess: true, displayLoadingIcon: false}) )
        .catch(() => this.setState({orderDidProcess: false, displayLoadingIcon: false }) )
    }


    createNewCampaignMember = (email, firstName, lastName) => {
        let startDate = moment(this.state.parentOfSelectedEvent.X1_Day_Start_DateTime__c).calendar()
        const newCampaignMember = {
            FirstName: firstName,
            LastName: lastName,
            campaignMemberEmail: email,
            campaignId: this.state.selectedEventId,
            eventStartDate: startDate,
            userId: 'undefined'
        }
        
        return axios.post(`${campaignMemberBaseURL}`, newCampaignMember)
        .then(res => res)
        .catch(err => err)
    }

    validateInputs = () => {
        let inputs = document.querySelectorAll("input, select, textarea");

        inputs.forEach(input => {
            input.addEventListener(
            "invalid",
            () => {
                input.classList.add("error");
            },
            false
            );
        });
    }


    render(){
        return (
            <RegistrationContext.Provider 
                value={{
                    ...this.state,
                    handleChangeRegistration : this.handleChangeRegistration,
                    handleChangeParticipant: this.handleChangeParticipant,
                    handleBooleanChangeRegistration: this.handleBooleanChangeRegistration,
                    handleAddParticipant: this.handleAddParticipant,
                    handleDeleteParticipant: this.handleDeleteParticipant,
                    handleNextStep: this.handleNextStep,
                    handleBackStep: this.handleBackStep,
                    checkPromoCode: this.checkPromoCode,
                    removePromoCode: this.removePromoCode,
                    toggleUseParticipantInfo: this.toggleUseParticipantInfo,
                    cancelUseParticipantInfo: this.cancelUseParticipantInfo,
                    noPayment: this.noPayment,
                    handleSubmitRegistration: this.handleSubmitRegistration,
                    handleProgramSelection: this.handleProgramSelection,
                    toggleEditParticipantModal: this.toggleEditParticipantModal,
                    handleEditParticipant: this.handleEditParticipant,
                    handleUpdateEditedParticipant: this.handleUpdateEditedParticipant,
                    toggleEditBillingContactModal: this.toggleEditBillingContactModal,
                    toggleEditBillingAddressModal: this.toggleEditBillingAddressModal,
                    toggleDeleteParticipantModal: this.toggleDeleteParticipantModal,
                    clearRegistrationProviderState: this.clearRegistrationProviderState,
                    toggleLoader: this.toggleLoader,
                    handleSelectCountry: this.handleSelectCountry,
                    handleSelectState: this.handleSelectState,
                    validateInputs: this.validateInputs,
                    windowReset: this.windowReset,
                    generateOrderNumber: this.generateOrderNumber
                }}>
                { this.props.children }
            </RegistrationContext.Provider>
        )
    }
}

export default RegistrationProvider;

export const withRegistration = C => props => (
    <RegistrationContext.Consumer>
        {value => <C {...props} {...value}/>}
    </RegistrationContext.Consumer>
)
