import { useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCurrentWidth } from '../../../shared/CustomHooks/useCurrentWidth'
import { useSwipe } from '../../../shared/CustomHooks/useSwipe'
import { ClientLogosData } from './ClientLogosData'

const ClientsCarousel = ({ heading }) => {
    const [currentIndex, setCurrentIndex] = useState(1)
    const [handleTouchStart, handleTouchMove, handleTouchEnd] = useSwipe()
    let currentWindowWidth = useCurrentWidth()

    let logosPerView = 3
    if(currentWindowWidth > 1200){
        logosPerView = 7
    } else if(currentWindowWidth > 1000) {
        logosPerView = 6
    } else if(currentWindowWidth > 900) {
        logosPerView = 5
    } else if(currentWindowWidth > 600) {
        logosPerView = 4
    }

    // Create a copy of the array (not set it as a reference)
    const logos = [...ClientLogosData]
    
    let groupedLogos = []
    // Loop through the copied array and group them by 3
    while(logos.length > 0) {
        groupedLogos.push(logos.splice(0, logosPerView))
    }

    const numberOfLogos = groupedLogos.length - 1

    const nextCard = useCallback(() => { 
        currentIndex < numberOfLogos ? setCurrentIndex(currentIndex + 1) : setCurrentIndex(0)
    }, [currentIndex, numberOfLogos])

    const previousCard = () => {
        currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(numberOfLogos)
    }

    useEffect(() => {
        let interval = 5000
        let autoplay = numberOfLogos > 3 ? true : false
        if(autoplay){ 
            interval = setInterval(() => nextCard(), interval);
        }
        return () => clearInterval(interval)
    }, [currentIndex, numberOfLogos, nextCard])

    const clientLogoMap = groupedLogos.map((group, i) => {
        return (
            <aside key={i}>
                {group.map(logo => (
                    <img 
                        key={logo.alt} 
                        src={logo.logo} 
                        alt={logo.alt} 
                        className={currentIndex === i ? 'card-one' : 'card-hidden'} 
                        title={logo.alt} 
                    />
                ))}
            </aside>
        )
    })


    return (
        <article className='carousels-clients'>
            <header className='section-header'>
                <h2>{heading}</h2>
            </header>
            <div className='carousels-clients-container'>
                {(numberOfLogos + 1 > 3 || currentWindowWidth) <= 1024 &&
                    <FontAwesomeIcon 
                        icon={"caret-left"} 
                        className='carousels-clients-container-arrow' onClick=
                        {previousCard}
                    />
                }
                <section 
                    className='carousels-clients-container-map' 
                    onTouchStart={(e) => handleTouchStart(e)} 
                    onTouchMove={(e) => handleTouchMove(e)} 
                    onTouchEnd={() => handleTouchEnd(previousCard, nextCard)}
                >
                    {clientLogoMap}
                </section>
                {(numberOfLogos + 1 > 3 || currentWindowWidth <= 1024) &&
                    <FontAwesomeIcon 
                        icon={"caret-right"} 
                        className='carousels-clients-container-arrow' 
                        onClick={nextCard}
                    />
                }
            </div>
        </article>
    )
}

export default ClientsCarousel
