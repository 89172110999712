// This file contains routes from our old servers that were not being used any longer on the new site but were requested to be preserved from the SEO Contractors.
import { lazy, Suspense } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { PublicRoute } from './PublicRoute'

const NotFound404 = lazy(() => import('../../GlobalComponents/NotFound404/NotFound404'))
// These are for testing the redirects only:
const BlogHome = lazy(() => import('../../Communities/PublicWebsite/Blog/BlogHome'))
const Contact = lazy(() => import('../../Communities/PublicWebsite/Contact/ContactPage'))



const SeoBacklinkRoutes = () => {

    const renderLoader = () => <p>Loading...</p>

    return (
        <Suspense fallback={renderLoader()}>
            <Switch>
                <PublicRoute 
                    exact path={`/?webSyncID=6731d334-7aae-a8ae-1ce2-dbc9d72724e8&sessionGUID=e1007604-caa1-e86c-3a9c-33e14a060ee6&__hsfp=239609110`} 
                    component={BlogHome} // Since its a querry string on the root dir this wont work and redirects to home regardless
                />
                <PublicRoute 
                    exact path={`/gen-z`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/coaching-training.asp`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/company/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/corporate-training-approach/alan-fine/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/demo/assets/images/img_menu_page1.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/insideout-breakthroughs/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/ioc4.0/images/1024/whats_new_grow.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/ioc4.0/images/1024/whats_new_ioc.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/leadership-coaching/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/newsletter/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/newsletter/04-2015/`}
                    component={Contact} 
                />
                <PublicRoute 
                    exact path={`/newsletter/06-2014/styles/Client-Success-Story-Article.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/newsletter/12-2016/img/HR_Animation2.gif`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/our-approach/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/our-approach/alan-fine/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/our-approach/the-grow-model/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/pivot-point/`}
                    component={BlogHome} 
                />
                <PublicRoute exact path={`/pivot-point/2014/01/3-characteristics-of-effective-feedback/`} >
                    <Redirect to='/blog/3-Characteristics-of-Effective-Feedback'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2014/06/the-secret-sauce-for-great-leadership/`} >
                    <Redirect to='/blog/The-Secret-Sauce-for-Great-Leadership'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2014/08/3-road-lessons-to-live-by/`} >
                    <Redirect to='/blog/3-Road-Lessons-to-Lead-By'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2014/11/quieting-the-fluctuations-of-the-managers-mind/`} >
                    <Redirect to='/blog/quieting-the-fluctuations-of-the-managers-mind'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2015/02/pickupthephone/`} >
                    <Redirect to='/blog/PickUpThePhone'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2015/04/speaking-your-leaders-language/`} >
                    <Redirect to='/blog/Speaking-Your-Leaders-Language'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2015/08/the-power-of-planning/`} >
                    <Redirect to='/blog/The-Power-of-Planning'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2015/09/coaching-remotely-its-all-about-communication/`} >
                    <Redirect to='/blog/coaching-remotely-its-all-about-communication'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2016/03/your-team-hates-you-heres-why/`} >
                    <Redirect to='/blog/your-team-hates-you-heres-why'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2016/11/remote-workers-holding-meetings-that-connect-with-remote-workers/`} >
                    <Redirect to='/blog/Holding-Meetings-That-Connect-with-Remote-Workers'/>
                </PublicRoute>
                <PublicRoute exact path={`/pivot-point/2017/03/4-ways-list-leaders-engage-teams/`} >
                    <Redirect to='/blog/4-Ways-A-List-Leaders-Engage-Their-Teams'/>
                </PublicRoute>
                <PublicRoute 
                    exact path={`/site/about/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/site/history_grow_model/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/site/management_training`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/site/site/coaching_definition/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/solutions/insideout-coaching/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/top-20-training-companies/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/facilitator/cache/journal/page.php?ed9071=ddj-400-in-stock`}
                    component={BlogHome} // Redirects to portal cuz its a root path for a community on the new site
                />
                <PublicRoute 
                    exact path={`/corporate-training-approach/the-grow-model/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/ioc4.0/images/1024/whats_new_app_sustain2.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/ioc4.0/images/1024/whats_new_ioc.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/workshops/coaching-as-a-manager-must/`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/03/icon_grow_model-222x300.png,qx34735.pagespeed.ce.5OXtknvbtS.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/03/icon_grow_model-222x300.png,qx34735.pagespeed.ce.5OXtknvbtS.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/03/icon_grow_model-222x300.png?x34735`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/03/icon_grow_model-222x300.png?x94743`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/03/icon_grow_model-222x300.pngqx34735.pagespeed.ce.5OXtknvbtS.png`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2017/06/4-Coaching-Check-In-Questions_sm.png?x34735`}
                    component={BlogHome} 
                />
                <PublicRoute 
                    exact path={`/wp-content/uploads/2018/04/web_ebook_Core-Coaching-Characteristics-L.png?x34735`}
                    component={BlogHome} 
                />
                <PublicRoute path='*' component={NotFound404} />
            </Switch>
        </Suspense>
    )
} 

export default SeoBacklinkRoutes