import 'react-app-polyfill/ie9' // Enables react to work on IE 9 and above
import 'react-app-polyfill/stable'
// import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import './styles/main.scss'
import './sass/main.scss'
import App from './App'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import AuthProvider from './Context/AuthProvider'
import EventsProvider from './Context/EventsProvider'
import RegistrationProvider from './Context/RegistrationProvider'
import FeaturesProvider from './Context/FeaturesProvider'
import SnapshotProvider from './Context/SnapshotProvider'
import { SnapshotsProviderV2 } from './Context/SnapshotsProviderV2'
import UserProvider from './Context/UsersProvider'
import CompanyProvider from './Context/CompanyProvider'
import TutorialsProvider from './Context/TutorialsProvider'
import FeedbackProvider from './Context/FeedbackProvider'
import FacilitatorProvider from './Context/FacilitatorProvider'
import CampaignMembersProvider from './Context/CampaignMembersProvider'
import CoachingSessionsProvider from './Context/Sessions/CoachingSessionsProvider'
import DFSessionsProvider from './Context/Sessions/DFSessionsProvider'
import SessionsProvider from './Context/Sessions/SessionsProvider'
import SessionParticipantsProvider from './Context/Sessions/SessionParticipantsProvider'
import DigitalFoundationsProvider from './Context/DigitalFoundationsProvider'
import EmailSubscriptionProvider from './Context/EmailSubscriptionsProvider'
import PulseProvider from './Context/PulseProvider'
import { UtilityProvider } from './Context/UtilityProvider'
import { PulseProviderV2 }  from './Context/PulseProviderV2'
import { HighFiveProviderV2 } from './Context/HighFiveProviderV2'
import InviteParticipantsProvider from './Context/InviteParticipantsProvider'
import { TagsProvider } from './Context/TagsProvider'
import { EmployeeProviderV2 } from './Context/EmployeeProviderV2'
import { nodeEnvironment } from './config/Config'
import { initGoogleAnalytics, googlePageView } from './shared/GoogleAnalyticsTracking'
import { Chart, registerables } from 'chart.js'
// Storyblok
import { storyblokInit, apiPlugin } from "@storyblok/react"
import StoryblokHeader from './GlobalComponents/Storyblok/StoryblokHeader'
import StoryblokTeaser from './GlobalComponents/Storyblok/StoryblokTeaser'
import StoryblokPage from './GlobalComponents/Storyblok/StoryblokPage'
import StoryblokGrid from './GlobalComponents/Storyblok/StoryblokGrid'
import StoryblokResourceLanding from './GlobalComponents/Storyblok/StoryblokResourceLanding'
import StoryblokResourceCard from './GlobalComponents/Storyblok/StoryblokResourceCard'
import StoryblokResourcePost from './GlobalComponents/Storyblok/StoryblokResourcePost'
import StoryblokResourceHeader from './GlobalComponents/Storyblok/StoryblokResourceHeader'

//  Configures Google Analytics on production server only
if( nodeEnvironment === 'production' ){
  initGoogleAnalytics()
  googlePageView()
}

//  Removes unnecessary react-dom warnings until Facebook updates their package 
if( nodeEnvironment === 'development' ){
  const warn = console.warn;
  const logWarning = (...warnings ) => {
    let showWarning = true
    warnings.forEach(warning => {
      if(warning.includes("componentWillMount"))  showWarning = false;
    });
    if(showWarning) warn(...warnings);
  }
  console.warn  = logWarning
}

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_ACCESS_TOKEN,
  use: [apiPlugin],
  // apiOptions: {
    // storyblok-js-client config object
  // },
  bridge: process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
  components: {
    page: StoryblokPage,
    teaser: StoryblokTeaser,
    grid: StoryblokGrid,
    resourceLanding: StoryblokResourceLanding,
    resourceCard: StoryblokResourceCard,
    header: StoryblokHeader,
    resourcePost: StoryblokResourcePost,
    resourceHeading: StoryblokResourceHeader
  }
})


Chart.register(...registerables)


// HighFiveComplementsProvider
const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <UtilityProvider>
    <AuthProvider>
      <EventsProvider>
        <RegistrationProvider>
          <FeaturesProvider>
            <SnapshotsProviderV2>
              <SnapshotProvider>
                <CompanyProvider>
                  <UserProvider>
                    <CampaignMembersProvider>
                      <TutorialsProvider>
                        <CoachingSessionsProvider>
                          <FeedbackProvider>
                            <FacilitatorProvider>
                              <DigitalFoundationsProvider> 
                                <EmailSubscriptionProvider>
                                  <SessionsProvider>
                                    <SessionParticipantsProvider>
                                      <DFSessionsProvider>
                                        <EmployeeProviderV2>
                                          <PulseProvider>
                                            <PulseProviderV2>
                                              <HighFiveProviderV2> 
                                                <InviteParticipantsProvider>
                                                  <TagsProvider>
                                                    <InviteParticipantsProvider>
                                                      <Router onUpdate={() => window.scrollTo(0, 0)} history={createBrowserHistory()}>
                                                        <App />
                                                      </Router>
                                                    </InviteParticipantsProvider>
                                                  </TagsProvider>
                                                </InviteParticipantsProvider>
                                              </HighFiveProviderV2>
                                            </PulseProviderV2>
                                          </PulseProvider>
                                        </EmployeeProviderV2>
                                      </DFSessionsProvider>
                                    </SessionParticipantsProvider>
                                  </SessionsProvider>
                                </EmailSubscriptionProvider>
                              </DigitalFoundationsProvider>
                            </FacilitatorProvider>
                          </FeedbackProvider>
                        </CoachingSessionsProvider>
                      </TutorialsProvider>
                    </CampaignMembersProvider>
                  </UserProvider>
                </CompanyProvider>
              </SnapshotProvider>
            </SnapshotsProviderV2>
          </FeaturesProvider>
        </RegistrationProvider>
      </EventsProvider>
    </AuthProvider>
  </UtilityProvider>
)
