// This file contains routes from our old react communities that were replaced with the Participant Portal. We are keeping these links live for a transition period
import { Redirect, Route } from 'react-router-dom'

const OldReactCommunityRoutes = () => {
    return (
        <>
            <Route exact path="/coaching">
                <Redirect to='/participant-portal' />
            </Route>
            <Route path='/coaching/tools-resources'>
                <Redirect to='/participant-portal/library' />
            </Route>
            <Route path='/coaching/snapshots/report'>
                <Redirect to='/participant-portal/snapshots/insideout-coaching' />
            </Route>
            <Route path='/coaching/snapshots/list'>
                <Redirect to='/participant-portal/snapshots/insideout-coaching' />
            </Route>
            <Route path='/coaching/snapshots'>
                <Redirect to='/participant-portal/snapshots/insideout-coaching' />
            </Route>
            <Route path='/coaching/connect'>
                <Redirect to='/participant-portal' />
            </Route>
            <Route path='/coaching/welcome'>
                <Redirect to='/participant-portal/insideout-coaching/prework' />
            </Route>
            <Route path='/account-settings'>
                <Redirect to='/participant-portal/preferences' />
            </Route>
        </>
    )
}

export default OldReactCommunityRoutes