// Imported on Public routes, Will expand into protected when we get designs
// If using normal Route, we will need to import both a header and footer if we want them displayed
// Once designs are in place, pass a community prop from the route to set the navigation

import { Link, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { appDomain } from '../../config/Config'
import moment from 'moment'
import IODLogo from '../../Assets/images/iod-logo-horizantal.svg'
import navData from '../globalNavLinks.json'

const Footer = () => {
    const copywriteDate = moment().format("YYYY")

    const footerNavLinks = navData.iodNavLinks.map(link => (
        <li key={link.linkName}>
            <NavLink to={link.to} 
                className='sprout-footer-content-nav-links-item' activeClassName='sprout-footer-content-nav-links-item-active'>
                {link.linkName}
            </NavLink>
        </li>
    ))

    return (
        <footer className='sprout-footer sprout-typography'>
            <article className='sprout-footer-content'>
                <section className='sprout-footer-content-nav'>
                    <div className='sprout-footer-content-nav-spacer'></div>
                    <Link to='/' className='sprout-footer-content-nav-logo'>
                        <img src={IODLogo} alt="InsideOut Development" className='sprout-footer-content-nav-logo-img'/>
                    </Link>

                    <ul className='sprout-footer-content-nav-links text-body-sm'>
                        {footerNavLinks}
                    </ul>
                </section>

                <section className='sprout-footer-content-contact'>
                    <h2 className='h3'>Contact</h2>

                    <div className='sprout-footer-content-contact-info text-body-sm'>
                        <address>
                            <p>270 N Main St Suite B</p>
                            <p>Alpine, UT 84004</p>
                        </address>

                        <div>
                            <a href='tel:1-888-262-2448'>1 888 262 2448</a>
                            <a href="mailto:info@insideoutdev.com">info@insideoutdev.com</a>
                        </div>
                    </div>
                </section>

                <section className='sprout-footer-content-contact'>
                    <h2 className='h3'>Websites</h2>

                    <ul className='sprout-footer-content-nav-links text-body-sm'>
                        <li>
                            <a href={`${appDomain}/dashboard`} className='sprout-footer-content-nav-links-item' >Dashboard</a>
                        </li>
                        <li>
                            <a href="https://facilitator.insideoutdev.com/"  className='sprout-footer-content-nav-links-item'  target="_blank" rel="noopener noreferrer" >Facilitators</a>
                        </li>
                        <li>
                            <a href="https://coaching.insideoutdev.com/" className='sprout-footer-content-nav-links-item'  target="_blank" rel="noopener noreferrer" >Coaching</a>
                        </li>
                        <li>
                            <a href="https://breakthroughs.insideoutdev.com/"  className='sprout-footer-content-nav-links-item'  target="_blank" rel="noopener noreferrer" >Breakthroughs</a>
                        </li>
                    </ul>
                </section>

                <ul className='sprout-footer-content-social'>
                    <li>
                        <a href='https://www.linkedin.com/company/insideout-development/'>
                            <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.facebook.com/InsideOutDev/'>
                            <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                        </a>
                    </li>
                    <li>
                        <a href='https://twitter.com/insideoutdev'>
                            <FontAwesomeIcon icon={["fab", "twitter"]} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.youtube.com/user/InsideOutDev/featured'>
                            <FontAwesomeIcon icon={["fab", "youtube"]} />
                        </a>
                    </li>
                    <li>
                        <a href='https://www.instagram.com/insideoutdev/'>
                            <FontAwesomeIcon icon={["fab", "instagram"]} />
                        </a>
                    </li>
                </ul>
            </article>
            <aside className='sprout-footer-copywrite'>
                <small>&copy;{copywriteDate}, InsideOut Development</small>
                <small><Link to='/privacy-policy'>Privacy Policy</Link></small>
            </aside>
        </footer>
    )
}

export default Footer