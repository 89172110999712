import { storyblokEditable } from "@storyblok/react"

const StoryblokResourceHeader = ({ blok }) => {
    return (
        <header {...storyblokEditable(blok)} className='coachingLibrary-section-header'>
            <h2>{blok.title}</h2>
            <p>{blok.caption}</p>
        </header>
    )
}

export default StoryblokResourceHeader
