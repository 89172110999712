export const topicsData = [
    {
        name: 'Coaching Culture',
        value: 'coaching-culture'
    },
    {
        name: 'The GROW Model',
        value: 'the-grow-model'
    },
    {
        name: 'Training & Onboarding',
        value: 'training-onboarding'
    },
    {
        name: 'Generations',
        value: 'generations'
    },
    // {
    //     name: 'New Managers',
    //     value: 'new-managers'
    // },
    {
        name: 'Performance Management',
        value: 'performance-management'
    },
    {
        name: 'Conversations',
        value: 'conversations'
    },
    {
        name: 'Remote Workers',
        value: 'remote-workers'
    },
    {
        name: 'Employee Engagement',
        value: 'employee-engagement'
    },
    {
        name: 'Retention',
        value: 'retention'
    }
]


export const rolesData = [
    {
        name: 'Human Resources / L&D',
        value: 'human-resources-l-d'
    },
    {
        name: 'Executives',
        value: 'executives'
    },
    {
        name: 'People Managers',
        value: 'people-leaders'
    },
    // {
    //     name: 'New Managers',
    //     value: 'new-managers'
    // },
    {
        name: 'Individual Contributors',
        value: 'individual-contributors'
    }
]


export const typesData = [
    {
        name: 'Articles',
        value: 'articles'
    },
    // {
    //     name: 'Blog',
    //     value: 'workplace-coach-blog'
    // },
    {
        name: 'Case Studies',
        value: 'case-studies'
    },
    {
        name: 'ebooks',
        value: 'ebooks'
    },
    // {
    //     name: 'Infographics',
    //     value: 'infographics'
    // },
    // {
    //     name: 'Videos',
    //     value: 'videos'
    // },
    // {
    //     name: 'Webinars',
    //     value: 'webinars'
    // }
]